import { AppCommonAPI } from '@gtn/app-common/api/AppCommonAPI';
import { CompetenceType } from '@gtn/app-common/api/model/CompetenceType';
import { ExampleAndItemResponse, ItemStatus } from '@gtn/app-common/api/model/ExampleAndItemResponse';
import { SubmitItemRequest } from '@gtn/app-common/api/model/SubmitItemRequest';
import AnnotateImageDialog from '@gtn/app-common/components/annotate-image/AnnotateImageDialog';
import { AnnotatePdfDialog } from '@gtn/app-common/components/annotate-pdf-dialog/annotate-pdf-dialog.component';
import { GradingInput } from '@gtn/app-common/components/grading/GradingInput';
import ItemComments from '@gtn/app-common/components/item-comments/ItemComments';
import styles from '@gtn/app-common/components/submit-item/SubmitItemDialog.module.scss';
import { MoodleWebservice } from '@gtn/common/api/webservice/MoodleWebservice';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import { ChooseCollaborators } from '@gtn/common/components/choose-collaborators/ChooseCollaborators';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import GtnFileManager, { GtnFile } from '@gtn/common/components/forms/gtn-file-manager/GtnFileManager';
import { GTN_SELECT_DEFAULT_OPTION } from '@gtn/common/components/forms/gtn-select/GtnSelect';
import GtnForm from '@gtn/common/components/forms/GtnForm';
import GtnTextField from '@gtn/common/components/forms/GtnTextField';
import IframeDialog from '@gtn/common/components/IframeDialog';
import LinkPreview from '@gtn/common/components/link-preview/LinkPreview';
import LoadingContainer from '@gtn/common/components/loading-container/LoadingContainer';
import GtnDialog, { DialogProps, useGtnDialog } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { useAppTranslation, useDebounce } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { Utils } from '@gtn/common/utils/Utils';
import { IconButton } from '@material-ui/core';
import { Create } from '@material-ui/icons';
import { FormikHelpers } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';

export interface SubmitItemProps {
  exampleAndItem?: ExampleAndItemResponse;
  onSave?: () => void;
}

interface SubmitItemFormValues {
  title?: string;
  text?: string;
  collaborators?: number[];
  studentValue?: string;
  descriptorGradings?: string[];
  isSubmit?: boolean;
}

function SubmitItemDialog(props: SubmitItemProps & DialogProps) {
  const exampleAndItem = props.exampleAndItem || ({} as ExampleAndItemResponse);

  const appCommonAPI = InjectionContainer.resolve(AppCommonAPI);
  const moodleWebservice = InjectionContainer.resolve(MoodleWebservice);

  const t = useAppTranslation();
  const annotateImageDialog = useGtnDialog(AnnotateImageDialog);
  const annotatePdfDialog = useGtnDialog(AnnotatePdfDialog);
  const iframeDialog = useGtnDialog(IframeDialog);

  const [initialFormValues, setInitialFormValues] = React.useState<SubmitItemFormValues>();
  const [files, setFiles] = React.useState<GtnFile[]>([]);
  const [removeFiles, setRemoveFiles] = React.useState<GtnFile[]>([]);
  const [submitFailed, setSubmitFailed] = React.useState(false);

  const hasExample = !!exampleAndItem.example;
  const canEdit = exampleAndItem.status === ItemStatus.New || exampleAndItem.status === ItemStatus.Inprogress;
  const isH5pExample = !!exampleAndItem.example?.externaltask_embedded;
  const courseId = exampleAndItem.courseid;

  const { data: descriptors, progressState: descriptorsProgressState } = useAPI(appCommonAPI.getDescriptorsForExampleItem, [
    exampleAndItem.courseid,
    0,
    exampleAndItem.example?.id,
    exampleAndItem.topicid,
  ]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape<SubmitItemFormValues>({
        text: Yup.string().required().label(t('submit-item.text')),
      }),
    []
  );

  useEffect(() => {
    if (exampleAndItem.item) {
      setInitialFormValues({
        title: exampleAndItem.item.name,
        text: exampleAndItem.item.solutiondescription,
        collaborators: exampleAndItem.item.collaborators?.map((user) => Number(user.userid)),
        studentValue: String(exampleAndItem.item.studentvalue),
        descriptorGradings: descriptors?.map((descriptor) => String(descriptor.studentevaluation ?? 0)),
      });

      if (exampleAndItem.item?.studentfiles) {
        setFiles(
          exampleAndItem.item?.studentfiles.map((file) => ({
            id: file.id,
            name: file.filename,
            url: file.file,
            type: file.mimetype,
          }))
        );
      }
    } else {
      setInitialFormValues({
        descriptorGradings: descriptors?.map((descriptor) => String(descriptor.studentevaluation ?? 0)),
      });
    }
  }, [exampleAndItem, descriptors]);

  const [text, setText] = useState('');
  const debouncedText = useDebounce(text, 1000);
  const textLinks = useMemo(() => Utils.extractUrls(debouncedText), [debouncedText]);

  const onSave = async (values: SubmitItemFormValues, formHelper: FormikHelpers<SubmitItemFormValues>) => {
    setSubmitFailed(false);

    try {
      const filesToUpload = files.filter((file) => file instanceof File);
      const moodleFiles = await Promise.all(filesToUpload.map((file) => moodleWebservice.uploadFile(file as File)));

      const descriptorGradings = values.descriptorGradings
        ? descriptors?.map((descriptor, index) => {
            const descriptorGrading = values.descriptorGradings?.[index];
            return {
              descriptorid: Number(descriptor.descriptorid),
              studentvalue: descriptorGrading !== GTN_SELECT_DEFAULT_OPTION ? Number(descriptorGrading) : -1,
            };
          })
        : [];

      const submitItemRequest: SubmitItemRequest = {
        courseid: courseId,
        url: null,
        itemid: exampleAndItem.item?.id || 0,

        // das item wird, wenn vorhanden, dem example zugeteilt, sonst dem ausgewählten topic
        compid: exampleAndItem.example?.id ? exampleAndItem.example?.id : exampleAndItem.topicid,
        comptype: exampleAndItem.example?.id ? CompetenceType.Example : CompetenceType.Topic,

        itemtitle: values.title || exampleAndItem.example?.title || '',
        solutiondescription: values.text || '',
        studentcomment: '', // TODO remove
        collabuserids: values.collaborators,

        filenames: moodleFiles.map((file) => file.filename),
        fileitemids: moodleFiles.map((file) => file.itemid),
        removefiles: removeFiles.map((file) => file.id) as number[],

        studentvalue: values.studentValue != null && values.studentValue !== GTN_SELECT_DEFAULT_OPTION ? Number(values.studentValue) : -1,
        descriptorgradings: descriptorGradings,

        submit: values.isSubmit || false,
      };

      const submitResult = await appCommonAPI.submitItem(submitItemRequest);

      if (submitResult.success) {
        props.onSave?.();
        props.onClose?.();
        return submitResult.itemid;
      } else {
        setSubmitFailed(true);
      }
    } catch (exception) {
      GtnLogger.warn(exception);
      setSubmitFailed(true);
    }

    formHelper.setSubmitting(false);
    return undefined;
  };

  function openAnnotateImageDialog(imageUrl: string, saveImageFileName: string) {
    annotateImageDialog.open({
      backgroundImageUrl: imageUrl,
      saveImageFileName,
    });
  }

  function addFiles(addedFiles: GtnFile[]) {
    const oldFiles = files.filter((file) => addedFiles.some((addedFile) => addedFile.name === file.name));
    const newFiles = files.filter((file) => !oldFiles.some((oldFile) => oldFile === file));
    setFiles([...newFiles, ...addedFiles]);

    setRemoveFiles([...removeFiles, ...oldFiles.filter((oldFile) => oldFile?.id)]);
  }

  const attachAnnotatedPDF = async (content: ArrayBuffer, originalFileName?: string) => {
    try {
      const blob = new Blob([content], { type: 'application/pdf' });
      const file = new File([blob], originalFileName || 'Anmerkungen.pdf', { type: 'application/pdf' });
      addFiles([file]);
      annotatePdfDialog.close();
    } catch (e) {
      GtnLogger.warn(e);
    }
  };

  function renderAttachedImage(url: string, name: string = 'bild.png') {
    return (
      <div className={styles.headerImage} style={{ ['--aspect-ratio' as any]: 16 / 6 }}>
        <img src={url} />
        <IconButton
          className={styles.editHeaderImageButton}
          onClick={(e) => {
            openAnnotateImageDialog(appCommonAPI.getRequestExternalFileUrl(url), name);
            e.preventDefault();
          }}
        >
          <Create />
        </IconButton>
      </div>
    );
  }

  async function loadH5pResults() {
    if (exampleAndItem.example?.id && canEdit) {
      try {
        const result = await appCommonAPI.getLastH5pActivityResult(exampleAndItem.example.id);
        if (result) {
          setInitialFormValues({ text: t('submit-item.h5p-result-text', { points: result.current_result.raw_score, maxPoints: result.current_result.max_score, link: result.resultpage_url }) });
        }
      } catch (e) {}
    }
    iframeDialog.close();
  }

  const example = (
    <>
      {exampleAndItem.example?.taskfiles?.filter((taskFile) => taskFile.type.includes('image')).map((taskFile) => renderAttachedImage(taskFile.url, taskFile.name))}

      {exampleAndItem.example?.externaltask && Utils.isImagePath(exampleAndItem.example?.externaltask) && renderAttachedImage(exampleAndItem.example?.externaltask)}

      {hasExample ? <h2>{exampleAndItem.example?.title ?? exampleAndItem.item?.name}</h2> : <GtnTextField name="title" label={t('submit-item.title')} disabled={!canEdit} />}

      <p className={styles.competence}>
        {t('competence-area')}&nbsp;
        <b>
          {exampleAndItem.subjecttitle} / {exampleAndItem.topictitle}
        </b>
      </p>

      {hasExample && (
        <>
          <h3 className={styles.sectionHeader}>{t('submit-item.header-task')}</h3>
          <p className={styles.description}>
            <p>{exampleAndItem.example?.description}</p>
            <p>{exampleAndItem.example?.annotation}</p>
            <p className={styles.taskFileLink}>
              {exampleAndItem.example?.externalurl && (
                <a href={exampleAndItem.example?.externalurl} target="_blank" rel="noopener noreferrer">
                  {exampleAndItem.example?.externalurl}
                </a>
              )}
              {exampleAndItem.example?.externaltask && !isH5pExample && (
                <a href={exampleAndItem.example?.externaltask} target="_blank" rel="noopener noreferrer">
                  {exampleAndItem.example?.externaltask}
                </a>
              )}

              {isH5pExample && (
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    iframeDialog.open({ title: exampleAndItem.example?.title, src: exampleAndItem.example?.externaltask_embedded, disableCloseByClickingOutside: true });
                  }}
                >
                  {t('Aufgabe öffnen')}
                </a>
              )}
            </p>
          </p>

          {exampleAndItem.example?.taskfiles
            ?.filter((taskFile) => !taskFile.type.includes('image'))
            .map((taskFile) => (
              <p className={styles.taskFileLink} key={taskFile.name}>
                <b>{taskFile.name}</b> &#40;
                <a href={taskFile.url} target="_blank" rel="noopener noreferrer">
                  {t('submit-item.open-file')}
                </a>
                {', '}
                <a href="#" onClick={() => annotatePdfDialog.open({ fileUrl: taskFile.url, fileName: taskFile.name })}>
                  {t('submit-item.annotate-pdf')}
                </a>
                &#41;
              </p>
            ))}
        </>
      )}
    </>
  );

  const solution = (
    <>
      <h3 className={styles.sectionHeader}>{t('submit-item.header-solution')}</h3>
      {canEdit && !isH5pExample && (
        <GtnFileManager
          className={styles.formElement}
          files={files}
          onFilesAdded={addFiles}
          onFileRemoved={(removedFile) => {
            if (removedFile.id) {
              setRemoveFiles([...removeFiles, removedFile]);
            }
            setFiles(files.filter((file) => file !== removedFile));
          }}
          onEditFile={(editFile) => {
            const fileUrl = editFile.url || URL.createObjectURL(editFile as File);
            if (editFile.type.includes('image')) {
              openAnnotateImageDialog(fileUrl, editFile.name);
            } else {
              annotatePdfDialog.open({ fileUrl, fileName: editFile.name });
            }
          }}
        />
      )}

      <GtnTextField className={styles.formElement} disabled={!canEdit || isH5pExample} name="text" label={!isH5pExample ? t('submit-item.text') + '*' : ''} multiline rows={4} />

      {textLinks.length > 0 && (
        <div className={styles.linkPreviewContainer}>
          {textLinks.map((url) => (
            <LinkPreview url={url} key={url} />
          ))}
        </div>
      )}

      <ChooseCollaborators className={styles.formElement} disabled={!canEdit} courseId={courseId} name="collaborators" />
    </>
  );

  const descriptorGradings = (
    <>
      <h3 className={styles.sectionHeader}>{t('evaluation.self-evaluation.title-overall')}:</h3>
      <div className={styles.studentEvaluationContainer}>
        <p>{t('evaluation.self-evaluation.description-overall')}</p>

        <GradingInput name="studentValue" label={t('evaluation.self-evaluation.placeholder')} className={styles.gradingSelector} />
      </div>

      <h3 className={styles.sectionHeader}>{t('evaluation.self-evaluation.title-competences')}</h3>
      <LoadingContainer state={descriptorsProgressState} className={styles.competenceGradingTable} emptyText={t('evaluation.self-evaluation.no-competences')}>
        <h4 style={{ textAlign: 'left', paddingLeft: 16 }}>{t('competence')}</h4>
        <h4>{t('evaluation.self-evaluation.placeholder')}</h4>

        {descriptors?.map((descriptor, index) => (
          <>
            <p>{descriptor.title}</p>

            <GradingInput name={`descriptorGradings.${index}`} label={t('evaluation.self-evaluation.placeholder')} className={styles.gradingSelector} />
            <hr />
          </>
        ))}
      </LoadingContainer>
    </>
  );

  return (
    <>
      <GtnDialog {...props} title={t('submit-item.header-dialog')} disableCloseByClickingOutside={true}>
        <GtnForm initialValues={initialFormValues} className={styles.dialogContentContainer} onSubmit={onSave} validationSchema={validationSchema}>
          {(formHelper) => {
            setText(formHelper.values.text || '');

            const saveForm = async () => {
              const values = {
                ...formHelper.values,
                isSubmit: false,
              };
              await formHelper.setValues(values, false);
              formHelper.setSubmitting(true);
              const saveResult = await onSave(values, formHelper);
              formHelper.setSubmitting(false);
              return saveResult;
            };

            return (
              <>
                <div className={styles.scrollContainer}>
                  {example}

                  {solution}

                  <hr />
                  <h3 className={styles.itemCommentsHeader}>{t('item-comments.title')}</h3>
                  <ItemComments
                    itemId={exampleAndItem.item?.id}
                    beforeSave={async () => {
                      if (!exampleAndItem.item?.id) {
                        const itemId = await saveForm();
                        return itemId ?? false;
                      }
                      return true;
                    }}
                    onOpenFile={(file) => {
                      if (file.mimetype.includes('pdf')) {
                        annotatePdfDialog.open({ fileUrl: file.file, fileName: file.filename });
                      } else {
                        window.open(file.file, '_blank');
                      }
                    }}
                  />

                  {descriptorGradings}
                </div>

                {canEdit && (
                  <div className={styles.submitContainer}>
                    <p>{submitFailed ? t('submit-item.error') : ''}</p>

                    <GtnButton
                      type="button"
                      onClick={async () => await saveForm()}
                      actionType="secondary"
                      label={t('save')}
                      disabled={!canEdit || formHelper.isSubmitting || !(formHelper.dirty || initialFormValues != undefined)}
                      loading={!formHelper.values.isSubmit && formHelper.isSubmitting}
                    />

                    <GtnButton
                      type="button"
                      onClick={async () => {
                        await formHelper.setValues({ ...formHelper.values, isSubmit: true }, true);
                        await formHelper.submitForm();
                      }}
                      actionType="primary"
                      label={t('submit-item.complete')}
                      disabled={!canEdit || formHelper.isSubmitting || !(formHelper.dirty || initialFormValues != undefined) || !formHelper.isValid}
                      loading={formHelper.values.isSubmit && formHelper.isSubmitting}
                    />
                  </div>
                )}
              </>
            );
          }}
        </GtnForm>
      </GtnDialog>

      <annotateImageDialog.Component
        onSavedAsImage={(imageFile) => {
          addFiles([imageFile]);
          annotateImageDialog.close();
        }}
      />
      <annotatePdfDialog.Component onSave={attachAnnotatedPDF} />
      <iframeDialog.Component onClose={() => loadH5pResults()} />
    </>
  );
}

export default SubmitItemDialog;
