import classNames from 'classnames';
import React from 'react';
import { useAppConfig } from '@gtn/app-common/config/AppConfigHooks';
import { useAppDispatch } from '@gtn/app-common/store/app.store.hooks';
import { navigationActions } from '@gtn/app-common/store/navigation.state';
import { StyleProps } from '@gtn/common/components/StyleProps';
import styles from '@gtn/app-common/components/app-info/AppInfo.module.scss';
import { useGtnDialog } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import AboutDialog from '@gtn/app-common/components/about-dialog/AboutDialog';

export default function AppInfo(props: StyleProps) {
  const t = useAppTranslation();
  const dispatch = useAppDispatch();
  const config = useAppConfig();
  const aboutDialog = useGtnDialog(AboutDialog);

  return (
    <>
      <div
        className={classNames(styles.buildInfo, props.className)}
        style={props.style}
      >
        <p>
          {config.theme.appName} v{config.version.name}
        </p>
        <a
          href="#"
          onClick={() => {
            dispatch(navigationActions.setNavigationDrawerOpened(false));
            aboutDialog.open();
          }}
        >
          {t('about.title')}
        </a>
      </div>
      <aboutDialog.Component />
    </>
  );
}
