import { CommonRoutingParams, CommonRoutingPaths } from '@gtn/app-common/routes/AppCommonRouting';
import { ConfigManagerToken } from '@gtn/common/config/ConfigManager';
import { useUser } from '@gtn/common/store/user/user.hooks';
import React from 'react';
import { AppConfigManager } from '@gtn/app-common/config/AppConfigManager';
import { useAppDispatch, useAppCommonSelector } from '@gtn/app-common/store/app.store.hooks';
import { navigationActions } from '@gtn/app-common/store/navigation.state';
import { MSTeamsManager } from '@gtn/app-common/microsoft/MSTeamsManager';
import { LoadingIndicatorOverlay } from '@gtn/common/components/LoadingIndicator';
import { userActions } from '@gtn/common/store/user/user.state';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { Utils } from '@gtn/common/utils/Utils';
import useAsyncEffect from 'use-async-effect';
import { LoginService } from '@gtn/app-common/api/LoginService';

let logoutRedirectStarted = false;

export default function Logout() {
  const msTeamsManager = InjectionContainer.resolve(MSTeamsManager);
  const loginService = InjectionContainer.resolve(LoginService);
  const moodleUrl = useAppCommonSelector((state) => state.preferences.moodleUrl);

  const t = useAppTranslation();
  const dispatch = useAppDispatch();
  const user = useUser();

  useAsyncEffect(async () => {
    dispatch(userActions.userLogout());
    // dispatch(appSETActions.reset());
    dispatch(navigationActions.reset());

    // hack: nach dem logout verschiebt sich der index im "routes" array in der AppNavigation()
    // darum wird Logout() zwei mal gerendert
    // mit diesem Check wird sichergestellt, dass die Weiterleitung nach dem logout nur einmal getriggert wird
    if (logoutRedirectStarted) {
      return;
    } else {
      logoutRedirectStarted = true;
    }

    const initialLoginQueryParams = loginService.getInitialQueryParams();
    const loginSite = msTeamsManager.isInMsTeams() ? CommonRoutingPaths.MS_TEAMS_TAB : CommonRoutingPaths.LOGIN;

    const configProvider = InjectionContainer.resolve<AppConfigManager>(ConfigManagerToken);
    const basePath = Utils.normalizeBasePath(configProvider.getConfig().basePath);

    const returnUri = `${document.location.origin + basePath}/${loginSite}` + (initialLoginQueryParams ? `?${initialLoginQueryParams}` : '');

    const sesskey = user.authTokens?.sessionKey;

    if (sesskey) {
      // with moodle session key, also clear moodle session
      const logoutUrl = `${moodleUrl}/blocks/exacomp/applogin_diggr_plus.php?action=logout&return_uri=${encodeURIComponent(returnUri)}&sesskey=${encodeURIComponent(sesskey)}`;
      GtnLogger.log(`Logout url: ${logoutUrl}`);
      document.location.href = logoutUrl;
    } else {
      // reload the app
      document.location.href = returnUri;
    }
  }, []);

  return <LoadingIndicatorOverlay>{t('logout.text')}</LoadingIndicatorOverlay>;
}
