import { BaseMoodleAPI } from '@gtn/common/api//BaseMoodleAPI';
import { singleton } from 'tsyringe';

@singleton()
export class DiggrPlusAPI extends BaseMoodleAPI {
  public importMSTeamsStudents = async (courseId: number, msGraphAccessToken: string, msTeamsTeamId: string) => {
    return this.moodleWebservice.diggrplus_msteams_import_students({
      courseid: courseId,
      access_token: msGraphAccessToken,
      teamid: msTeamsTeamId,
    });
  };
}
