import { ExampleAndItemResponse, ItemStatus } from '@gtn/app-common/api/model/ExampleAndItemResponse';
import { ExampleItemsDialog } from '@gtn/app-common/components/example-item-list/example-items-dialog/ExampleItemsDialog';
import { ExamplesOverview } from '@gtn/app-common/components/example-item-list/examples-overview/ExamplesOverview';
import { LargeItemList } from '@gtn/app-common/components/example-item-list/large-item-list/LargeItemList';
import ReviewItemDialog from '@gtn/app-common/components/review-item/ReviewItemDialog';
import SubmitItemDialog from '@gtn/app-common/components/submit-item/SubmitItemDialog';
import { ListStyle, TypeFilter } from '@gtn/app-common/components/workbook/Workbook';
import { useUnreadItemNotifications } from '@gtn/common/api/MoodleCoreAPIHooks';
import { useGtnDialog } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { useIsTeacher } from '@gtn/common/store/user/user.hooks';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import React from 'react';

export interface ExampleItemListProps {
  items?: ExampleAndItemResponse[];
  reloadItems?: () => any;
  statusFilter: ItemStatus | null;
  typeFilter: TypeFilter | null;
  listStyle?: ListStyle;
}

export function ExampleItemList(props: ExampleItemListProps) {
  const t = useAppTranslation();
  const isTeacher = useIsTeacher();

  const exampleItemsDialog = useGtnDialog(ExampleItemsDialog);
  const submitItemDialog = useGtnDialog(SubmitItemDialog);
  const reviewItemDialog = useGtnDialog(ReviewItemDialog);

  const { markNotificationRead } = useUnreadItemNotifications();

  const onItemClicked = async (exampleAndItem: ExampleAndItemResponse) => {
    if (isTeacher || exampleAndItem.status === ItemStatus.Submitted || exampleAndItem.status === ItemStatus.Completed) {
      reviewItemDialog.open({ exampleAndItem });
    } else {
      submitItemDialog.open({ exampleAndItem });
    }

    markNotificationRead(exampleAndItem.item?.id);
  };

  const onExampleClicked = (exampleId: number, topicId?: number, title?: string, preSelectedStatusFilter?: ItemStatus) => {
    exampleItemsDialog.open({
      title,
      exampleId,
      topicId,
      items: props.items?.filter((i) => (exampleId > -1 ? i.example?.id === exampleId : i.topicid === topicId)),
      preSelectedStatusFilter,
    });
  };

  return (
    <>
      {props.items?.length ? (
        props.listStyle !== ListStyle.Grid ? (
          <ExamplesOverview items={props.items} statusFilter={props.statusFilter} typeFilter={props.typeFilter} onItemClicked={onItemClicked} onExampleOrTopicClicked={onExampleClicked} />
        ) : (
          <LargeItemList items={props.items} onItemClicked={onItemClicked} />
        )
      ) : (
        <div>{t('list-empty')}</div>
      )}

      <exampleItemsDialog.Component onItemClicked={onItemClicked} />
      <submitItemDialog.Component onSave={props.reloadItems} />
      <reviewItemDialog.Component onSave={props.reloadItems} />
    </>
  );
}
