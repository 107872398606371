import { AppCommonAPI } from '@gtn/app-common/api/AppCommonAPI';
import { ExampleAndItemResponse, ItemStatus } from '@gtn/app-common/api/model/ExampleAndItemResponse';
import { AnnotatePdfDialog } from '@gtn/app-common/components/annotate-pdf-dialog/annotate-pdf-dialog.component';
import { GradingInput } from '@gtn/app-common/components/grading/GradingInput';
import ItemComments from '@gtn/app-common/components/item-comments/ItemComments';
import styles from '@gtn/app-common/components/review-item/ReviewItemDialog.module.scss';
import { MoodleWebservice } from '@gtn/common/api/webservice/MoodleWebservice';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import { ChooseCollaborators } from '@gtn/common/components/choose-collaborators/ChooseCollaborators';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import GtnFileManager from '@gtn/common/components/forms/gtn-file-manager/GtnFileManager';
import { GTN_SELECT_DEFAULT_OPTION } from '@gtn/common/components/forms/gtn-select/GtnSelect';
import GtnCheckbox from '@gtn/common/components/forms/GtnCheckbox';
import GtnForm from '@gtn/common/components/forms/GtnForm';
import LinkPreview from '@gtn/common/components/link-preview/LinkPreview';
import GtnDialog, { DialogProps, useGtnDialog } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { useIsTeacher } from '@gtn/common/store/user/user.hooks';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { Utils } from '@gtn/common/utils/Utils';
import { FormikHelpers } from 'formik';
import React, { useEffect, useMemo } from 'react';

export interface ReviewItemProps {
  exampleAndItem?: ExampleAndItemResponse;
  onSave?: () => void;
}

interface ReviewItemFormValues {
  completed?: boolean;
  collaborators?: number[];
  descriptorGradings?: { completed: boolean; value: string }[];
}

function ReviewItemDialog(props: ReviewItemProps & DialogProps) {
  const t = useAppTranslation();
  const isTeacher = useIsTeacher();

  const exampleAndItem = props.exampleAndItem || ({} as ExampleAndItemResponse);

  const [initialFormValues, setInitialFormValues] = React.useState<ReviewItemFormValues>();
  const [submitFailed, setSubmitFailed] = React.useState(false);
  const annotatePdfDialog = useGtnDialog(AnnotatePdfDialog);

  const hasExample = !!exampleAndItem.example;
  const courseId = exampleAndItem.courseid;

  const studentCommentLinks = useMemo(() => Utils.extractUrls(exampleAndItem.item?.solutiondescription), [exampleAndItem.item?.solutiondescription]);
  const studentFiles = useMemo(() => {
    return exampleAndItem.item?.studentfiles?.map((file) => ({
      id: file.id,
      name: file.filename,
      url: file.file,
      type: file.mimetype,
    }));
  }, [exampleAndItem]);

  const moodleWebservice = InjectionContainer.resolve(MoodleWebservice);
  const appCommonAPI = InjectionContainer.resolve(AppCommonAPI);

  const { data: descriptors } = useAPI(appCommonAPI.getDescriptorsForExampleItem, [
    exampleAndItem.courseid,
    isTeacher ? exampleAndItem.item?.owner?.userid : undefined,
    exampleAndItem.example?.id,
    exampleAndItem.topicid,
  ]);

  const commentsRef = React.useRef<React.ElementRef<typeof ItemComments>>(null);

  useEffect(() => {
    if (exampleAndItem.item) {
      const students = [exampleAndItem.item.owner.userid];
      students.push(...(exampleAndItem.item.collaborators?.map((user) => user.userid) ?? []));

      setInitialFormValues({
        collaborators: students,
        completed: exampleAndItem.status === ItemStatus.Completed,
        descriptorGradings: descriptors?.map((descriptor) => ({
          value: String(descriptor.teacherevaluation ?? 0),
          completed: descriptor.teacherevaluation != null ? descriptor.teacherevaluation >= 0 : false,
        })),
      });
    }
  }, [exampleAndItem, descriptors]);

  const onSave = async (values: ReviewItemFormValues, formHelper: FormikHelpers<ReviewItemFormValues>) => {
    setSubmitFailed(false);

    try {
      const descriptorGradings = values.descriptorGradings
        ? descriptors?.map((descriptor, index) => {
            const completed = values.descriptorGradings?.[index].completed;
            const descriptorGrading = values.descriptorGradings?.[index].value;
            return {
              descriptorid: Number(descriptor.descriptorid),
              teachervalue: completed && descriptorGrading !== GTN_SELECT_DEFAULT_OPTION ? Number(descriptorGrading) : -1,
            };
          })
        : undefined;

      const submitResult = await appCommonAPI.gradeItem({
        itemid: exampleAndItem.item!.id,
        teachervalue: values.completed ? 1 : -1,
        descriptorgradings: descriptorGradings,
      });

      if (submitResult.success) {
        props.onClose?.();
        props.onSave?.();
      } else {
        setSubmitFailed(true);
      }
    } catch (exception) {
      GtnLogger.warn(exception);
      setSubmitFailed(true);
    }

    formHelper.setSubmitting(false);
  };

  const example = (
    <>
      {exampleAndItem.example?.taskfiles
        ?.filter((taskFile) => taskFile.type.includes('image'))
        .map((taskFile) => (
          <div className={styles.headerImage} style={{ ['--aspect-ratio' as any]: 16 / 6 }}>
            <img src={taskFile.url} />
          </div>
        ))}

      <h2>{exampleAndItem.example?.title ?? exampleAndItem.item?.name}</h2>

      <p className={styles.competence}>
        {t('competence-area')}:&nbsp;
        <b>
          {exampleAndItem.subjecttitle} / {exampleAndItem.topictitle}
        </b>
      </p>

      {hasExample && (
        <>
          <h3 className={styles.sectionHeader}>{t('submit-item.header-task')}</h3>
          <p className={styles.description}>
            <p>{exampleAndItem.example?.annotation}</p>
            <p className={styles.taskFileLink}>
              {exampleAndItem.example?.externalurl && (
                <a href={exampleAndItem.example?.externalurl} target="_blank" rel="noopener noreferrer">
                  {exampleAndItem.example?.externalurl}
                </a>
              )}
              {exampleAndItem.example?.externaltask && (
                <a href={exampleAndItem.example?.externaltask} target="_blank" rel="noopener noreferrer">
                  {exampleAndItem.example?.externaltask}
                </a>
              )}
            </p>
          </p>

          {exampleAndItem.example?.taskfiles?.map((taskFile) => (
            <p className={styles.link} key={taskFile.name}>
              <b>{taskFile.name}</b>
              &nbsp;(
              <a href={taskFile.url} target="_blank" rel="noopener noreferrer">
                {t('submit-item.open-file')}
              </a>
              )
            </p>
          ))}
        </>
      )}
    </>
  );

  const solution = (
    <>
      <h3 className={styles.sectionHeader}>{t('review-item.header-solution')}</h3>
      <div className={styles.solutionContainer}>
        <GtnFileManager
          className={styles.formElement}
          disabled={true}
          files={studentFiles}
          openFile={(file) => {
            if (file.type.includes('pdf')) {
              annotatePdfDialog.open({ fileUrl: file.url, fileName: file.name });
              return true;
            }
            return false;
          }}
        />

        <p>{exampleAndItem.item?.solutiondescription}</p>

        {studentCommentLinks.length > 0 && (
          <div className={styles.linkPreviewContainer}>
            {studentCommentLinks.map((url) => (
              <LinkPreview url={url} key={url} />
            ))}
          </div>
        )}
      </div>

      <ChooseCollaborators className={styles.formElement} disabled={true} courseId={courseId} name="collaborators" />
    </>
  );

  const renderGradingTable = (formHelper) => {
    return (
      <div className={styles.competenceGradingTable}>
        <h4 style={{ textAlign: 'left', paddingLeft: 42 }}>{t('competence')}</h4>
        <h4>{t('role.student')}</h4>
        <h4>{t('role.teacher')}</h4>

        {descriptors?.map((descriptor, index) => (
          <>
            <GtnCheckbox className={styles.gradingCheckbox} label={descriptor.title} disabled={!isTeacher} name={`descriptorGradings.${index}.completed`} />

            <GradingInput readonly={true} grading={descriptor.studentevaluation} noGradingDisplay={'-'} className={styles.studentSelfEvaluationIcon} />

            <GradingInput readonly={!isTeacher} name={`descriptorGradings.${index}.value`} disabled={!formHelper.values.descriptorGradings?.[index].completed} className={styles.gradingSelector} />
            <hr />
          </>
        ))}
      </div>
    );
  };

  const saveAnnotatedPDF = async (content: ArrayBuffer, originalFileName?: string) => {
    try {
      const blob = new Blob([content], { type: 'application/pdf' });
      const file = new File([blob], originalFileName || 'Anmerkungen.pdf', { type: 'application/pdf' });

      const fileUploadResponse = await moodleWebservice.uploadFile(file);
      const response = await appCommonAPI.submitComment(props.exampleAndItem!.item!.id, '', fileUploadResponse.itemid);
      annotatePdfDialog.close();
      commentsRef.current?.reloadComments();
    } catch (e) {
      GtnLogger.warn(e);
    }
  };

  return (
    <>
      <GtnDialog {...props} title={t('submit-item.header-dialog')} disableCloseByClickingOutside={true}>
        <div className={styles.dialogContentContainer}>
          <GtnForm initialValues={initialFormValues} className={styles.dialogContentContainer} onSubmit={onSave}>
            {(formHelper) => (
              <>
                <div className={styles.scrollContainer}>
                  {example}

                  {solution}

                  {exampleAndItem.item?.id != null && (
                    <>
                      <hr />
                      <h3 className={styles.itemCommentsHeader}>{t('item-comments.title')}</h3>
                      <ItemComments
                        itemId={exampleAndItem.item?.id}
                        ref={commentsRef}
                        onOpenFile={(file) => {
                          if (file.mimetype.includes('pdf')) {
                            annotatePdfDialog.open({ fileUrl: file.file, fileName: file.filename });
                          } else {
                            window.open(file.file, '_blank');
                          }
                        }}
                      />
                    </>
                  )}

                  {!isTeacher && (
                    <div className={styles.teacherEvaluation}>
                      <hr />
                      <h3>{t('evaluation.self-evaluation.title-overall')}</h3>
                      <div className={styles.exampleSolvedContainer}>
                        <p>{t('evaluation.self-evaluation.description-overall')}</p>
                        <GradingInput readonly={true} grading={exampleAndItem?.item?.studentvalue} noGradingDisplay={'-'} className={styles.studentSelfEvaluationIcon} />
                        <GradingInput readonly={true} grading={exampleAndItem?.item?.teachervalue} className={styles.studentSelfEvaluationIcon} />
                      </div>

                      {descriptors && descriptors.length > 0 && (
                        <>
                          <h3>{t('evaluation.self-evaluation.title-competences')}</h3>

                          {renderGradingTable(formHelper)}
                        </>
                      )}
                    </div>
                  )}

                  {isTeacher && (exampleAndItem?.status === ItemStatus.Submitted || exampleAndItem?.status === ItemStatus.Completed) && (
                    <div className={styles.teacherEvaluation}>
                      <hr />
                      <h3>{t('review-item.header-teacher-evaluation')}</h3>
                      <div className={styles.exampleSolvedContainer}>
                        <GtnCheckbox label={t('review-item.mark-as-completed')} name="completed" />

                        <GradingInput readonly={true} grading={exampleAndItem?.item?.studentvalue} noGradingDisplay={'-'} />
                      </div>

                      {descriptors && descriptors.length > 0 && (
                        <>
                          <h3>{t('review-item.header-teacher-competences-grading')}</h3>
                          <p>{t('review-item.subheader-teacher-competences-grading')}</p>

                          {renderGradingTable(formHelper)}
                        </>
                      )}
                    </div>
                  )}
                </div>

                {isTeacher && (
                  <div className={styles.submitContainer}>
                    <p>{submitFailed ? t('submit-item.error') : ''}</p>

                    <GtnButton
                      type="button"
                      onClick={async () => {
                        const values = {
                          ...formHelper.values,
                          isSubmit: false,
                        };
                        await formHelper.setValues(values, false);
                        formHelper.setSubmitting(true);
                        await commentsRef.current?.submitComment();
                        await onSave(values, formHelper);
                      }}
                      actionType="primary"
                      label={t('save')}
                      disabled={formHelper.isSubmitting || !formHelper.dirty}
                      loading={formHelper.isSubmitting}
                    />
                  </div>
                )}
              </>
            )}
          </GtnForm>
        </div>
      </GtnDialog>

      <annotatePdfDialog.Component onSave={saveAnnotatedPDF} />
    </>
  );
}

export default ReviewItemDialog;
