import { AppCommonAPI } from '@gtn/app-common/api/AppCommonAPI';
import { ExamplesTreeDescriptor, ExamplesTreeExample, ExamplesTreeSubject, ExamplesTreeTopic } from '@gtn/app-common/api/model/ExamplesTreeResponse';
import { ExampleVisibilityManager, NavigatableElement } from '@gtn/app-common/components/examples-list/ExampleVisibilityManager';
import { CreateEditExample } from '@gtn/app-common/components/examples/create-edit-example/CreateEditExample';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import { AlertDialog } from '@gtn/common/components/alert-dialog/AlertDialog';
import { GtnTreeNode, GtnTreeParent, GtnTreeView } from '@gtn/common/components/gtn-tree-view/GtnTreeView';
import { GtnSnackbar } from '@gtn/common/components/GtnSnackbar';
import LoadingContainer from '@gtn/common/components/loading-container/LoadingContainer';
import { useGtnDialog } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { StyleProps } from '@gtn/common/components/StyleProps';
import { useUser } from '@gtn/common/store/user/user.hooks';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { ProgressState } from '@gtn/common/utils/ProgressState';
import { Utils } from '@gtn/common/utils/Utils';
import { Fab, IconButton, Tooltip } from '@material-ui/core';
import { DeleteForeverOutlined, PostAdd } from '@material-ui/icons';
import React, { useMemo, useState } from 'react';
import styles from './ExamplesList.module.scss';

export interface ExamplesListProps extends StyleProps {
  showCreateExample?: boolean;
  showEditExample?: boolean;
  showDeleteExample?: boolean;
  courseId: number;
  onExampleClicked?: (example: ExamplesTreeExample) => boolean;

  renderPrefix?(node: NavigatableElement): React.ReactNode;
  renderSuffix?(node: NavigatableElement): React.ReactNode;
}

export function ExamplesList(props: ExamplesListProps) {
  const t = useAppTranslation();
  const user = useUser();

  const createEditExampleDialog = useGtnDialog(CreateEditExample);
  const deleteExampleDialog = useGtnDialog(AlertDialog);

  const [deleteExampleErrorSnackbarOpen, setDeleteExampleErrorSnackbarOpened] = useState(false);
  const [searchFilter, setSearchFilter] = useState<string | undefined>(undefined);

  const appCommonAPI = InjectionContainer.resolve(AppCommonAPI);
  const { data: examplesTree, progressState, mutate: reloadItems } = useAPI(appCommonAPI.getExamplesForCourse, [props.courseId ?? 0]);

  const exampleVisibilityManager = new ExampleVisibilityManager(appCommonAPI, props.courseId);

  // Add references for easier navigation
  const navigableExamplesTree = useMemo(() => exampleVisibilityManager.createNavigateableExamplesTree(examplesTree), [examplesTree]);

  const filteredSubjects = useMemo(() => {
    if (searchFilter) {
      return Utils.filterTree<ExamplesTreeSubject, ExamplesTreeTopic>(
        navigableExamplesTree,
        'topics',
        (gradingTree) => Utils.includesSearch(gradingTree.title, searchFilter),
        (topics) => {
          return Utils.filterTree<ExamplesTreeTopic, ExamplesTreeDescriptor>(
            topics,
            'descriptors',
            (node) => Utils.includesSearch(node.title, searchFilter),
            (descriptors) =>
              Utils.filterTree<ExamplesTreeDescriptor, ExamplesTreeExample>(
                descriptors,
                'examples',
                (node) => Utils.includesSearch(node.title, searchFilter),
                (examples) => examples.filter((example) => Utils.includesSearch(example.title, searchFilter))
              )
          );
        }
      );
    } else {
      return navigableExamplesTree;
    }
  }, [navigableExamplesTree, searchFilter]);

  async function deleteExample(example: ExamplesTreeExample) {
    try {
      await appCommonAPI.deleteExample(example.id);
    } catch (e) {
      setDeleteExampleErrorSnackbarOpened(true);
      GtnLogger.warn(e);
    }

    await reloadItems();
  }

  function renderPrefix(node: NavigatableElement) {
    return props.renderPrefix?.(node) ?? <></>;
  }

  function renderSuffix(node: NavigatableElement) {
    return props.renderSuffix?.(node) ?? <></>;
  }

  function getTextWithCount(text: string, count: number, total: number) {
    return `${text} <i>(${count}/${total})</i>`;
  }

  function renderExamples(descriptor: ExamplesTreeDescriptor) {
    if (descriptor.examples?.length > 0) {
      return descriptor.examples.map((example) => (
        <div
          key={example.id}
          className={styles.exampleContainer}
          onClick={() => {
            const handled = props.onExampleClicked?.(example);
            if (!handled && props.showEditExample) {
              createEditExampleDialog.open({ exampleId: example.id });
            }
          }}
        >
          {renderPrefix(example as any)}

          <p dangerouslySetInnerHTML={Utils.highlightSearchText(example.title, searchFilter)} />

          {props.showDeleteExample && example.creatorid === user.profile?.id && (
            <Tooltip title={t('examples.delete') as string}>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  deleteExampleDialog.open({
                    message: t('examples.delete-example-warning', {
                      name: example.title,
                    }),
                    primaryButtonText: t('examples.delete'),
                    onPrimaryButtonClicked: () => deleteExample(example),
                  });
                }}
              >
                <DeleteForeverOutlined />
              </IconButton>
            </Tooltip>
          )}

          {renderSuffix(example as any)}
        </div>
      ));
    } else {
      return <p>{t('examples.empty')}</p>;
    }
  }

  function renderDescriptors(topic: ExamplesTreeTopic) {
    return (
      <>
        {topic.descriptors?.map((descriptor) => (
          <GtnTreeNode
            id={descriptor.id}
            defaultExpanded={topic.descriptors.length === 1}
            node={
              <>
                {renderPrefix(descriptor as any)}
                <h3
                  className={styles.title}
                  dangerouslySetInnerHTML={Utils.highlightSearchText(
                    getTextWithCount(
                      descriptor.title,
                      descriptor.visible
                        ? descriptor.examples.filter((d) => d.visible).length +
                            (descriptor.childdescriptors?.reduce((exCount, descriptor) => exCount + descriptor.examples.filter((d) => d.visible).length, 0) ?? 0)
                        : 0,
                      descriptor.examples.length + (descriptor.childdescriptors?.reduce((exCount, descriptor) => exCount + descriptor.examples.length, 0) ?? 0)
                    ),
                    searchFilter
                  )}
                />
              </>
            }
          >
            {descriptor.childdescriptors?.map((childDescriptor) => (
              <GtnTreeNode
                id={childDescriptor.id}
                defaultExpanded={descriptor.childdescriptors?.length === 1}
                node={
                  <>
                    {renderPrefix(childDescriptor as any)}
                    <h3
                      className={styles.title}
                      dangerouslySetInnerHTML={Utils.highlightSearchText(
                        getTextWithCount(childDescriptor.title, childDescriptor.visible ? childDescriptor.examples.filter((d) => d.visible).length : 0, childDescriptor.examples.length),
                        searchFilter
                      )}
                    />
                  </>
                }
              >
                {renderExamples(childDescriptor)}
              </GtnTreeNode>
            ))}

            {renderExamples(descriptor)}
          </GtnTreeNode>
        ))}
      </>
    );
  }

  function renderTopics(subject: ExamplesTreeSubject) {
    return (
      <>
        {subject.topics?.map((topic) => (
          <GtnTreeNode
            id={topic.id}
            defaultExpanded={subject.topics.length === 1}
            node={
              <>
                {renderPrefix(topic as any)}
                <h3
                  className={styles.title}
                  dangerouslySetInnerHTML={Utils.highlightSearchText(
                    getTextWithCount(
                      topic.title,
                      topic.visible
                        ? topic.descriptors.reduce(
                            (exCount, descriptor) =>
                              exCount +
                              descriptor.examples.filter((d) => d.visible).length +
                              (descriptor.childdescriptors?.reduce((exCount, descriptor) => exCount + descriptor.examples.filter((d) => d.visible).length, 0) ?? 0),
                            0
                          )
                        : 0,
                      topic.descriptors.reduce(
                        (exCount, descriptor) => exCount + descriptor.examples.length + (descriptor.childdescriptors?.reduce((exCount, descriptor) => exCount + descriptor.examples.length, 0) ?? 0),
                        0
                      )
                    ),
                    searchFilter
                  )}
                />
              </>
            }
          >
            {renderDescriptors(topic)}
          </GtnTreeNode>
        ))}
      </>
    );
  }

  function renderSubjects() {
    return (
      <>
        {filteredSubjects?.map((subject) => (
          <GtnTreeParent
            id={subject.id}
            node={
              <h2
                className={styles.title}
                dangerouslySetInnerHTML={Utils.highlightSearchText(
                  getTextWithCount(
                    subject.title,
                    subject.topics.reduce(
                      (count, topic) =>
                        count +
                        topic.descriptors.reduce(
                          (exCount, descriptor) =>
                            exCount +
                            descriptor.examples.filter((d) => d.visible).length +
                            (descriptor.childdescriptors?.reduce((exCount, descriptor) => exCount + descriptor.examples.filter((d) => d.visible).length, 0) ?? 0),
                          0
                        ),
                      0
                    ),
                    subject.topics.reduce(
                      (count, topic) =>
                        count +
                        topic.descriptors.reduce(
                          (exCount, descriptor) => exCount + descriptor.examples.length + (descriptor.childdescriptors?.reduce((exCount, descriptor) => exCount + descriptor.examples.length, 0) ?? 0),
                          0
                        ),
                      0
                    )
                  ),
                  searchFilter
                )}
              />
            }
            defaultExpanded={filteredSubjects.length === 1}
          >
            {renderTopics(subject)}
          </GtnTreeParent>
        ))}
      </>
    );
  }

  return (
    <>
      <div className={props.className} style={props.style}>
        <LoadingContainer
          state={progressState}
          style={{
            alignItems: progressState === ProgressState.Content ? 'stretch' : 'center',
            justifyContent: progressState === ProgressState.Content ? 'flex-start' : 'center',
          }}
        >
          <GtnTreeView showSearchBar={true} searchBarLabel={t('examples.search')} searchText={searchFilter} onSearchTextChanged={setSearchFilter}>
            {renderSubjects()}
          </GtnTreeView>
        </LoadingContainer>

        {props.showCreateExample && (
          <Fab className={styles.createExampleButton} variant="extended" color="secondary" onClick={() => createEditExampleDialog.open()}>
            <PostAdd className={styles.createExampleButtonIcon} />
            <span className={styles.createExampleButtonText}>{t('examples.add')}</span>
          </Fab>
        )}
      </div>

      <createEditExampleDialog.Component onSave={reloadItems} />
      <deleteExampleDialog.Component />

      <GtnSnackbar textResId="examples.error-deleting-example" open={deleteExampleErrorSnackbarOpen} onClose={() => setDeleteExampleErrorSnackbarOpened(false)} />
    </>
  );
}
