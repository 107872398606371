import { CommonRoutingPaths } from '@gtn/app-common/routes/AppCommonRouting';
import Login from '@gtn/app-common/routes/login/Login';
import Logout from '@gtn/app-common/routes/logout/Logout';
import MSTeamsAuth from '@gtn/app-common/routes/ms-teams-auth/MSTeamsAuth';
import MSTeamsTabConfig from '@gtn/app-common/routes/ms-teams-tab-config/MSTeamsTabConfig';
import MSTeamsSSOStart from '@gtn/app-common/routes/ms-teams-tab/MSTeamsSSOStart';
import MSTeamsTab from '@gtn/app-common/routes/ms-teams-tab/MSTeamsTab';
import Profile from '@gtn/app-common/routes/profile/Profile';
import Settings from '@gtn/app-common/routes/settings/Settings';
import NoMatch from '@gtn/common/components/navigation/no-match/NoMatch';
import { GtnRoute } from '@gtn/common/utils/routing/GtnRoute';
import React from 'react';

export function getCommonRoutes() {
  const routes: GtnRoute[] = [
    {
      path: `/${CommonRoutingPaths.LOGIN}`,
      exact: true,
      content: () => <Login />,
      requireLogin: false,
      title: 'login.sign-in',
    },
    {
      path: `/${CommonRoutingPaths.LOGOUT}`,
      exact: true,
      content: () => <Logout />,
      requireLogin: false,
    },
    {
      path: `/${CommonRoutingPaths.SETTINGS}`,
      content: () => <Settings />,
      title: 'menu.settings',
    },
    {
      path: `/${CommonRoutingPaths.PROFILE}`,
      content: () => <Profile />,
      title: 'menu.profile',
    },
    {
      path: `/${CommonRoutingPaths.MS_TEAMS_TAB}`,
      content: () => <MSTeamsTab />,
      requireLogin: false,
    },
    {
      path: `/${CommonRoutingPaths.MS_TEAMS_AUTH}`,
      content: () => <MSTeamsAuth />,
      requireLogin: false,
    },
    {
      path: `/${CommonRoutingPaths.MS_TEAMS_SSO_START}`,
      content: () => <MSTeamsSSOStart />,
      requireLogin: false,
    },
    {
      path: `/${CommonRoutingPaths.MS_TEAMS_CONFIG}`,
      content: () => <MSTeamsTabConfig />,
      requireLogin: false,
    },
    {
      title: 'page-not-found',
      content: () => <NoMatch />,
      requireLogin: false,
    },
  ];

  return routes;
}
