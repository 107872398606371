import React from 'react';
import { useSelector } from 'react-redux';
import { Route as ReactRoute } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';
import { SharedState } from '@gtn/common/store/shared.store';
import { GtnRoute } from '@gtn/common/utils/routing/GtnRoute';

const PublicRoute = ReactRoute;
const PrivateRoute = (props) =>
  !useSelector<SharedState>((state: SharedState) => state.user.isLoggedIn) ? (
    <ReactRoute
      {...props}
      children={null}
      component={null}
      render={({ location }) => (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      )}
    />
  ) : (
    <ReactRoute {...props} />
  );

export interface GtnRouteSwitchProps {
  routes: GtnRoute[];
  render(route: GtnRoute);
}

export const GtnRouteSwitch = (props: GtnRouteSwitchProps) => (
  <Switch>
    {props.routes.map((route, index) => {
      const Route =
        route.requireLogin || route.requireLogin === undefined
          ? PrivateRoute
          : PublicRoute;
      if (!route.blocked) {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            render={() => props.render(route)}
          />
        );
      }
    })}
  </Switch>
);
