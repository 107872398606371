import { GtnSelectOption } from '@gtn/common/components/forms/gtn-select/GtnSelect';
import { SentimentSatisfied, SentimentVeryDissatisfied, SentimentVerySatisfied } from '@material-ui/icons';
import React from 'react';

const iconStyle = { width: 18, height: 18 };
export const GRADING_VALUES_EMOJI_3: GtnSelectOption[] = [
  {
    value: 1,
    displayValue: <SentimentVerySatisfied style={iconStyle} />,
    backgroundColor: '#67AC5B',
    color: 'white',
  },
  {
    value: 2,
    displayValue: <SentimentSatisfied style={iconStyle} />,
    backgroundColor: '#5CB3E0',
    color: 'white',
  },
  {
    value: 3,
    displayValue: <SentimentVeryDissatisfied style={iconStyle} />,
    backgroundColor: '#F5BD51',
    color: 'white',
  },
];

export const GRADING_VALUES_TEXT_3: GtnSelectOption[] = [
  {
    value: 1,
    displayValue: 'evaluation.gradings.1',
    backgroundColor: '#67AC5B',
    color: 'white',
  },
  {
    value: 2,
    displayValue: 'evaluation.gradings.2',
    backgroundColor: '#5CB3E0',
    color: 'white',
  },
  {
    value: 3,
    displayValue: 'evaluation.gradings.3',
    backgroundColor: '#F5BD51',
    color: 'white',
  },
];
