import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PreferencesState {
	moodleUrl?: string;
	language?: string;
	initialQueryParams?: string;
	unusedEnrolCode?: string;
}

const initialState: PreferencesState = {};

export const preferencesFeatureKey = 'preferences';

const preferencesSlicer = createSlice({
	name: preferencesFeatureKey,
	initialState,
	reducers: {
		set: (state, action: PayloadAction<Partial<PreferencesState>>): PreferencesState => ({
			...state,
			...action.payload,
		}),
	},
});

export const preferencesActions = preferencesSlicer.actions;
export const preferencesReducer = preferencesSlicer.reducer;
