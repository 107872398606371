import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import { ConfigManagerToken } from '@gtn/common/config/ConfigManager';
import { ErrorTrackingService } from '@gtn/app-common/utils/error-tracking/ErrorTrackingService';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import { useAppConfig } from '@gtn/app-common/config/AppConfigHooks';
import { useAppCommonSelector } from '@gtn/app-common/store/app.store.hooks';
import styles from '@gtn/app-common/components/about-dialog/AboutDialog.module.scss';
import GtnDialog, { GtnDialogProps } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import React from 'react';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { AppConfigManager } from '@gtn/app-common/config/AppConfigManager';

export default function AboutDialog(props: GtnDialogProps) {
  const t = useAppTranslation();
  const config = useAppConfig();
  const preferences = useAppCommonSelector((state) => state.preferences);
  const configManager = InjectionContainer.resolve<AppConfigManager>(ConfigManagerToken);
  const errorTrackingService = InjectionContainer.resolve(ErrorTrackingService);

  return (
    <GtnDialog {...props} title={t('about.title')}>
      <div className={styles.buildInfo}>
        <img src={configManager.getAssetPath('img/logo192x192.png')} />
        <h1>{config.theme.appName}</h1>
        <br />
        <p>
          <b>Version:</b> {config.version.name}
        </p>
        <p>
          <b>Build:</b> {config.buildTimeString}
        </p>
        <p>
          <b>Moodle:</b>{' '}
          <a href={preferences.moodleUrl} target="_blank">
            {preferences.moodleUrl}
          </a>
        </p>
        <br />
        <p>
          <b>{t('about.developers')}:</b>{' '}
          <a href="https://pro-web.at" target="_blank">
            pro-web.at
          </a>
          ,{' '}
          <a href="https://lizard-software.at" target="_blank">
            SlideLizard Software GmbH
          </a>
          ,{' '}
          <a href="https://gtn-solutions.com" target="_blank">
            GTN Solutions
          </a>
        </p>
        <br />
        <p>
          <b>{t('about.publisher')}:</b> GTN - global training network GmbH, Dipl.-Ing.-Heinrich-Treml-Str. 7, 4407 Steyr, Austria
        </p>
        <br />
        <br />
        <GtnButton
          label={t('about.send-logs')}
          onClick={() => {
            errorTrackingService.sendManuallyTriggeredReport();
            props.onClose?.();
          }}
        />
      </div>
    </GtnDialog>
  );
}
