import { Tooltip } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import React from 'react';
import { useLastLocation } from 'react-router-last-location';
import { IBaseConfig } from '@gtn/common/config/IBaseConfig';
import { MoodleCoreAPI } from '@gtn/common/api/MoodleCoreAPI';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import { useAppTranslation, useHistory } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnRoute } from '@gtn/common/utils/routing/GtnRoute';
import { GtnRouteSwitch } from '@gtn/common/utils/routing/GtnRouteSwitch';
import { GtnBadge } from '@gtn/common/components/gtn-badge/GtnBadge';
import styles from '@gtn/common/components/navigation/gtn-app-bar/GtnAppBar.module.scss';

export interface GtnAppBarProps {
  routes: GtnRoute[];
  showDrawerIcon?: boolean;
  showNotifications?: boolean;
  onDrawerIconClick?: () => void;
  onNotificationsClick?: () => void;
  config: IBaseConfig;
}

export default function GtnAppBar(props: GtnAppBarProps) {
  const history = useHistory();
  const t = useAppTranslation();
  const lastLocation = useLastLocation();

  const goBack = (route: GtnRoute) => {
    if (lastLocation != null) {
      history.goBack();
    } else if (route.backRoute) {
      history.push(route.backRoute);
    } else {
      history.push('/');
    }
  };

  const moodleAPI = InjectionContainer.resolve(MoodleCoreAPI);
  const { data: notificationData } = useAPI(moodleAPI.getExacompNotifications, [], {
    revalidateOnFocus: true,
    refreshInterval: 30 * 1000,
  });

  return (
    <AppBar position="static">
      <Toolbar variant="dense">
        <GtnRouteSwitch
          routes={props.routes}
          render={(route) => (
            <>
              {!route.backRoute && props.showDrawerIcon && (
                <IconButton color="inherit" edge="start" onClick={props.onDrawerIconClick}>
                  <MenuIcon />
                </IconButton>
              )}

              {route.backRoute && (
                <IconButton color="inherit" edge="start" onClick={() => goBack(route)}>
                  <ArrowBackIcon />
                </IconButton>
              )}

              <h2 className={styles.title} onClick={props.onDrawerIconClick}>
                {route.title != null ? t(route.title) : props.config.theme.appName}
              </h2>
            </>
          )}
        />

        {props.showNotifications && (
          <GtnBadge showBadge={notificationData && notificationData.unreadcount > 0} content={notificationData?.unreadcount ?? 0} onClick={props.onNotificationsClick}>
            <Tooltip
              title={
                t('notifications.x-new', {
                  count: notificationData?.unreadcount ?? 0,
                }) as string
              }
            >
              <IconButton className={styles.notificationsButton}>
                <NotificationsIcon />
              </IconButton>
            </Tooltip>
          </GtnBadge>
        )}
      </Toolbar>
    </AppBar>
  );
}
