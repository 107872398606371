import unique from 'array-unique';

type KeysOfType<T, TProp> = { [P in keyof T]: T[P] extends TProp ? P : never }[keyof T];

export class Utils {
  public static readonly URL_PATTERN = /^((http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

  public static parseQuery(queryString: string) {
    const query = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
  }

  public static extractUrls(text?: string) {
    if (text) {
      // source: https://stackoverflow.com/questions/161738/what-is-the-best-regular-expression-to-check-if-a-string-is-a-valid-url?page=2&tab=votes
      let matchedLinks = Array.from(text.matchAll(/(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/g)).map(
        (link: any) => (link[1] ? '' : 'https://') + link[0]
      );
      matchedLinks = unique(matchedLinks);
      return matchedLinks;
    }
    return [];
  }

  public static filterNullOrUndefined<T>(value: T | undefined | null): value is T {
    return value !== undefined && value !== null;
  }

  public static includesSearch(text: string, searchText?: string) {
    return searchText ? text.toLowerCase().includes(searchText.toLowerCase()) : true;
  }

  public static arrayNotEmpty<T>(array?: T[] | null): array is T[] {
    return array != null && array.length > 0;
  }

  /**
   * Highlights all occurrences of the search text as bold. Meant to be used with 'dangerouslySetInnerHTML'.
   * @param text
   * @param search
   */
  public static highlightSearchText(text?: string, search?: string) {
    let html = text;
    if (text && search) {
      html = text.replace(new RegExp(`(${search})`, 'gi'), (substring) => `<b>${substring}</b>`);
    }
    return this.getAsInnerHTML(html);
  }

  public static getAsInnerHTML(html?: string) {
    return { __html: html ?? '' };
  }

  public static filterTree<Parent, Child>(
    elements: Parent[] | undefined,
    childrenProp: KeysOfType<Parent, Child[]>,
    filterParent: (element: Parent) => boolean,
    mapChildren: (children: Child[]) => (Child | undefined)[] | undefined
  ) {
    return elements
      ?.map((parent) => {
        if (filterParent(parent)) {
          return parent;
        }

        const filteredChildren = mapChildren(parent[childrenProp] as any)?.filter(Utils.filterNullOrUndefined);
        if (filteredChildren?.length) {
          return { ...parent, [childrenProp]: filteredChildren };
        }
        return null;
      })
      .filter(Utils.filterNullOrUndefined);
  }

  public static isImagePath(path?: string) {
    return ['.jpg', '.jpeg', '.png', '.svg'].some((extension) => path?.toLowerCase().includes(extension));
  }

  public static deepMerge<T>(target: T, ...sources: Partial<T>[]): T {
    const isObject = (item: any): boolean => {
      return item === Object(item) && !Array.isArray(item);
    };

    // return the target if no sources passed
    if (!sources.length) {
      return target;
    }

    const result: T = target;

    if (isObject(result)) {
      const len: number = sources.length;

      for (let i = 0; i < len; i += 1) {
        const elm: any = sources[i];

        if (isObject(elm)) {
          for (const key in elm) {
            if (elm.hasOwnProperty(key)) {
              if (isObject(elm[key])) {
                if (!result[key] || !isObject(result[key])) {
                  result[key] = {};
                }
                this.deepMerge(result[key], elm[key]);
              } else {
                if (Array.isArray(result[key]) && Array.isArray(elm[key])) {
                  // concatenate the two arrays and remove any duplicate primitive values
                  result[key] = Array.from(new Set(result[key].concat(elm[key])));
                } else {
                  result[key] = elm[key];
                }
              }
            }
          }
        }
      }
    }

    return result;
  }

  public static groupBy<T>(list: T[], keyGetter: (T: T) => string | number) {
    const map: { [k: string]: T[] } = {};

    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map[key];
      if (!collection) {
        map[key] = [item];
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  public static capitalize(text?: string) {
    return text ? text.charAt(0).toUpperCase() + text.slice(1) : text;
  }

  public static normalizeBasePath(basePath: string) {
    return basePath.replace('./', '');
  }

  public static blobToDataURL(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (_e) => resolve(reader.result as string);
      reader.onerror = (_e) => reject(reader.error);
      reader.onabort = (_e) => reject(new Error('Read aborted'));
      reader.readAsDataURL(blob);
    });
  }

  public static async triggerBlobFileDownload(data: ArrayBuffer, type: string, fileName?: string) {
    const link = document.createElement('a');
    if (link.download !== undefined && data) {
      const url = await Utils.blobToDataURL(new Blob([data], { type }));
      link.setAttribute('href', url);
      link.setAttribute('download', fileName ?? 'download');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  public static toTimestamp(date?: Date) {
    return date != null ? Math.round(date.getTime() / 1000) : 0;
  }

  public static toDate(date?: number) {
    return date ? new Date(date * 1000) : new Date();
  }

  public static isEduvidual(moodleUrl?: string): boolean {
    return moodleUrl?.includes('eduvidual') ?? false;
  }

  public static fixUrl(url?: string): string | undefined {
    if (url && !url.startsWith('http')) {
      return 'https://' + url;
    }
    return url;
  }
}
