import { AppCommonAPI } from '@gtn/app-common/api/AppCommonAPI';
import { AnnotateExampleRequest } from '@gtn/app-common/api/model/AnnotateExampleRequest';
import { CreateEditExampleRequest } from '@gtn/app-common/api/model/CreateEditExampleRequest';
import { ExacompDescriptor } from '@gtn/app-common/api/model/ExacompDescriptor';
import { ExamplesTreeDescriptor } from '@gtn/app-common/api/model/ExamplesTreeResponse';
import { ChooseDescriptorDialog } from '@gtn/app-common/components/examples/choose-descriptor-dialog/ChooseDescriptorDialog';
import styles from '@gtn/app-common/components/examples/create-edit-example/CreateEditExample.module.scss';
import { useAppCommonSelector } from '@gtn/app-common/store/app.store.hooks';
import { ServerInfoManager } from '@gtn/app-common/utils/ServerInfoManager';
import { MoodleWebservice } from '@gtn/common/api/webservice/MoodleWebservice';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import GtnFileManager, { GtnFile } from '@gtn/common/components/forms/gtn-file-manager/GtnFileManager';
import { GTN_SELECT_DEFAULT_OPTION, GtnSelect, GtnSelectOption } from '@gtn/common/components/forms/gtn-select/GtnSelect';
import GtnForm from '@gtn/common/components/forms/GtnForm';
import GtnTextField from '@gtn/common/components/forms/GtnTextField';
import IframeDialog from '@gtn/common/components/IframeDialog';
import { LoadingIndicatorInline } from '@gtn/common/components/LoadingIndicator';
import GtnDialog, { DialogProps, useGtnDialog } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { useUser } from '@gtn/common/store/user/user.hooks';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { Utils } from '@gtn/common/utils/Utils';
import { IconButton, Tooltip } from '@material-ui/core';
import { AddCircle, CancelRounded } from '@material-ui/icons';
import { FormikHelpers } from 'formik';
import React, { useEffect, useMemo } from 'react';
import * as Yup from 'yup';

export interface CreateEditExampleProps {
  exampleId?: number;
  onSave?: () => void;
  preSelectedDescriptors?: Partial<ExamplesTreeDescriptor>[];
}

interface ExampleFormValues {
  name?: string;
  annotation?: string;
  externalUrl?: string;
  timeframe?: string;
  taxonomyId?: number;
}

export function CreateEditExample(props: CreateEditExampleProps & DialogProps) {
  const t = useAppTranslation();
  const user = useUser();
  const appCommonAPI = InjectionContainer.resolve(AppCommonAPI);
  const moodleWebservice = InjectionContainer.resolve(MoodleWebservice);
  const selectedCourse = useAppCommonSelector((state) => state.navigation.selectedCourse);
  const moodleUrl = useAppCommonSelector((state) => state.preferences.moodleUrl);
  const chooseDescriptorDialog = useGtnDialog(ChooseDescriptorDialog);
  const iframeDialog = useGtnDialog(IframeDialog);

  const [initialFormValues, setInitialFormValues] = React.useState<ExampleFormValues>();
  const [files, setFiles] = React.useState<GtnFile[]>([]);
  const [removeFiles, setRemoveFiles] = React.useState<GtnFile[]>([]);
  const [submitFailed, setSubmitFailed] = React.useState(false);
  const [h5PActivityId, setH5PActivityId] = React.useState<number | undefined>();
  const [descriptors, setDescriptors] = React.useState<Partial<ExamplesTreeDescriptor>[] | undefined>();

  const validationSchema = Yup.object().shape<ExampleFormValues>({
    name: Yup.string().required().label(t('create-edit-example.example-title')),
    externalUrl: Yup.string().label(t('create-edit-example.example-external-url')),
    timeframe: Yup.string().label(t('create-edit-example.duration')),
    taxonomyId: Yup.number().label(t('create-edit-example.niveau')),
  });

  const { data: existingExample, hasFinished } = useAPI(appCommonAPI.getExample, props.exampleId ? [selectedCourse?.id!, props.exampleId] : null, { useCache: false });

  const { data: descriptorsFromExample } = useAPI(appCommonAPI.getDescriptorsForExample, existingExample ? [selectedCourse?.id!, existingExample.id, 0, false] : null);

  const canEditExample = props.exampleId != null ? existingExample?.creatorid === user.profile?.id : true;

  useEffect(() => {
    if (hasFinished) {
      let taxonomyId: number | undefined = undefined;
      try {
        let firstTaxonomyId = existingExample?.exampletaxids?.split(',')?.[0];
        taxonomyId = firstTaxonomyId ? parseInt(firstTaxonomyId) : undefined;
      } catch (e) {}

      setInitialFormValues({
        name: existingExample?.title || '',
        annotation: existingExample?.annotation || '',
        externalUrl: existingExample?.externalurl || '',
        taxonomyId: taxonomyId,
        timeframe: existingExample?.timeframe || '',
      });

      if (existingExample?.taskfiles) {
        setFiles(
          existingExample.taskfiles.map((file, i) => ({
            id: i,
            name: file.name,
            url: file.url,
            type: file.type,
          }))
        );
      }
    }
  }, [hasFinished]);

  useEffect(() => {
    if (props.exampleId == null) {
      setDescriptors(props.preSelectedDescriptors);
    }
  }, [props.preSelectedDescriptors]);

  useEffect(() => {
    if (descriptorsFromExample?.length) {
      setDescriptors(
        descriptorsFromExample?.map((exacompDescriptor) => ({
          title: exacompDescriptor.title,
          id: exacompDescriptor.descriptorid,
          visible: true,
          examples: [],
          childdescriptors: undefined,
        }))
      );
    }
  }, [descriptorsFromExample]);

  const niveauSelectOptions = useMemo(() => {
    let options: GtnSelectOption[] = [{ value: undefined, displayValue: '' }];
    if (selectedCourse?.assessmentConfig?.taxonomies) {
      options = options.concat(
        selectedCourse.assessmentConfig.taxonomies.map((taxonomy) => ({
          value: taxonomy.id,
          displayValue: taxonomy.title || '-',
        }))
      );
    }
    return options;
  }, [selectedCourse]);

  const onSave = async (values: ExampleFormValues, formHelper: FormikHelpers<ExampleFormValues>) => {
    setSubmitFailed(false);

    const serverInfo = InjectionContainer.resolve(ServerInfoManager).getServerInfo();

    try {
      let exampleId = existingExample?.id;
      let submitResult;

      if (canEditExample) {
        const filesToUpload = files.filter((file) => file instanceof File);
        const moodleFiles = await Promise.all(filesToUpload.map((file) => moodleWebservice.uploadFile(file as File)));

        const exampleRequest: CreateEditExampleRequest = {
          exampleid: existingExample?.id,
          courseid: serverInfo?.example_upload_global
            ? 0 // global im kurs 0 erstellen
            : selectedCourse?.id!, // local im ausgewählten kurs erstellen
          name: values.name,
          externalurl: values.externalUrl,
          fileitemids: moodleFiles.map((file) => file.itemid),
          removefiles: removeFiles.map((file) => file.id) as number[],
          comps: descriptors?.map((descriptor) => descriptor.id!)?.filter((id) => id !== null),
          taxonomies: values.taxonomyId ? [values.taxonomyId] : undefined,
          timeframe: values.timeframe,
          activityid: h5PActivityId,
        };

        submitResult = await appCommonAPI.createEditExample(exampleRequest);
        if (!submitResult.success) {
          setSubmitFailed(true);
          formHelper.setSubmitting(false);
          return;
        }

        exampleId = submitResult.exampleid;
      }

      if (exampleId) {
        const annotateRequest: AnnotateExampleRequest = {
          exampleid: exampleId,
          courseid: selectedCourse?.id!,
          annotationtext: values.annotation ?? '',
        };
        submitResult = await appCommonAPI.annotateExample(annotateRequest);
      }

      if (submitResult.success) {
        props.onClose?.();
        props.onSave?.();
      } else {
        setSubmitFailed(true);
      }
    } catch (exception) {
      GtnLogger.warn(exception);
      setSubmitFailed(true);
    }

    formHelper.setSubmitting(false);
  };

  function addFiles(addedFiles: GtnFile[]) {
    const oldFiles = files.filter((file) => addedFiles.some((addedFile) => addedFile.name === file.name));
    const newFiles = files.filter((file) => !oldFiles.some((oldFile) => oldFile === file));
    setFiles([...newFiles, ...addedFiles]);

    setRemoveFiles([...removeFiles, ...oldFiles.filter((oldFile) => oldFile?.id)]);
  }

  function getCreateH5pUrl() {
    return `${moodleUrl}/blocks/exacomp/modedit.php?add=hvp&type=&course=${selectedCourse?.id}&section=0&return=0&sr=0`;
  }

  const example = (
    <>
      {canEditExample && <GtnTextField name="name" label={t('create-edit-example.example-title') + '*'} className={styles.formElement} />}

      {!canEditExample && <h2 className={styles.title}>{existingExample?.title}</h2>}

      {existingExample?.description && <p>{existingExample?.description}</p>}

      <GtnTextField className={styles.formElement} name="annotation" label={t('create-edit-example.example-text')} multiline rows={4} />

      {canEditExample && <GtnTextField name="timeframe" label={t('create-edit-example.duration')} className={styles.formElement} placeholder={t('create-edit-example.duration-format-info')} />}

      {canEditExample && niveauSelectOptions && (
        <GtnSelect name="taxonomyId" label={t('create-edit-example.niveau')} options={niveauSelectOptions} variant="outlined" className={styles.formElement} disableLabelAsDefaultOption={true} />
      )}

      {canEditExample && <GtnTextField name="externalUrl" label={t('create-edit-example.example-external-url')} className={styles.formElement} />}

      {existingExample == null && canEditExample && (
        <a href="#" onClick={() => iframeDialog.open({ src: getCreateH5pUrl() })} style={{ display: 'block', marginTop: 8 }}>
          {t('create-edit-example.add-h5p')}
        </a>
      )}

      {!canEditExample && (existingExample?.externaltask || existingExample?.externalurl) && (
        <>
          <h3 className={styles.filesHeader}>{t('create-edit-example.links')}</h3>
          {existingExample?.externaltask && (
            <a href={existingExample?.externaltask} className={styles.link} target="_blank">
              {existingExample?.externaltask}
            </a>
          )}
          {existingExample?.externalurl && existingExample?.externalurl !== existingExample?.externaltask && (
            <a href={existingExample?.externalurl} className={styles.link} target="_blank">
              {existingExample?.externalurl}
            </a>
          )}
        </>
      )}

      {(canEditExample || Utils.arrayNotEmpty(existingExample?.taskfiles)) && (
        <>
          <h3 className={styles.filesHeader}>{t('create-edit-example.files')}</h3>
          <GtnFileManager
            className={styles.formElement}
            files={files}
            disabled={!canEditExample}
            onFilesAdded={addFiles}
            onFileRemoved={(removedFile) => {
              if (removedFile.id) {
                setRemoveFiles([...removeFiles, removedFile]);
              }
              setFiles(files.filter((file) => file !== removedFile));
            }}
          />
        </>
      )}

      <div className={styles.competenciesHeader}>
        <h3>{t('competencies') + (canEditExample ? '*' : '')}</h3>
        {canEditExample && (
          <Tooltip title={t('create-edit-example.add-competencies') as string}>
            <IconButton onClick={() => chooseDescriptorDialog.open()}>
              <AddCircle />
            </IconButton>
          </Tooltip>
        )}
      </div>

      <div className={styles.competenciesContainer}>
        {descriptors?.map((descriptor) => (
          <div key={descriptor.id}>
            <p>{descriptor.title}</p>
            {canEditExample && (
              <Tooltip title={t('create-edit-example.remove-competencies') as string}>
                <IconButton onClick={() => setDescriptors(descriptors.filter((d) => d.id !== descriptor.id))}>
                  <CancelRounded />
                </IconButton>
              </Tooltip>
            )}
          </div>
        ))}
      </div>
    </>
  );

  const onH5pDialogMessage = (data: string) => {
    if (data) {
      try {
        const messageData = JSON.parse(data);
        if (messageData.coursemoduleid != null) {
          setH5PActivityId(messageData.coursemoduleid);
          iframeDialog.close();
        }
      } catch (e) {}
    }
  };

  return (
    <>
      <GtnDialog {...props} title={t(props.exampleId ? 'create-edit-example.edit-title' : 'create-edit-example.create-title')} disableCloseByClickingOutside={true}>
        {initialFormValues && (
          <GtnForm initialValues={initialFormValues} className={styles.dialogContentContainer} onSubmit={onSave} validationSchema={validationSchema}>
            {(formHelper) => (
              <>
                <div className={styles.scrollContainer}>{example}</div>

                <div className={styles.submitContainer}>
                  <p>{submitFailed ? t('submit-item.error') : ''}</p>

                  <GtnButton
                    type="button"
                    onClick={async () => {
                      await formHelper.setValues({ ...formHelper.values }, true);
                      await formHelper.submitForm();
                    }}
                    actionType="primary"
                    label={t('save')}
                    disabled={formHelper.isSubmitting || !formHelper.isValid || descriptors == null || descriptors.length === 0}
                    loading={formHelper.isSubmitting}
                  />
                </div>
              </>
            )}
          </GtnForm>
        )}
        {!hasFinished && <LoadingIndicatorInline />}
      </GtnDialog>

      <chooseDescriptorDialog.Component showChildDescriptors={true} chosenDescriptors={descriptors} onDescriptorsChosen={(descriptors) => setDescriptors(descriptors)} />
      <iframeDialog.Component onMessage={onH5pDialogMessage} />
    </>
  );
}
