import { GRADING_VALUES_EMOJI_3 } from '@gtn/app-common/components/grading/GradingValues';
import { GtnSelect } from '@gtn/common/components/forms/gtn-select/GtnSelect';
import { StyleProps } from '@gtn/common/components/StyleProps';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

export interface GradingInputProps extends StyleProps {
  name?: string;
  label?: string;
  grading?: number | null;
  disabled?: boolean;
  readonly?: boolean;
  onChange?: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
  noGradingDisplay?: ReactNode; // shown if readonly = true and no grading exists
}

export function GradingInput(props: GradingInputProps) {
  const t = useAppTranslation();
  const gradingValues = GRADING_VALUES_EMOJI_3;

  if (props.readonly === true) {
    // TODO: Fehler im if?
    if (props.grading != undefined) {
      const grading = gradingValues.find((gradingValue) => gradingValue.value === props.grading);

      if (grading) {
        return (
          <div
            className={classNames(props.className)}
            style={{
              display: 'flex',
              backgroundColor: grading.backgroundColor,
              color: grading.color,
              borderRadius: 24,
              paddingRight: 12,
              paddingLeft: 12,
              paddingTop: 4,
              paddingBottom: 4,
              textAlign: 'center',
              whiteSpace: 'nowrap',
              ...props.style,
            }}
          >
            {typeof grading.displayValue === 'string' ? t(grading.displayValue) : grading.displayValue}
          </div>
        );
      } else if (props.noGradingDisplay) {
        return (
          <div className={classNames(props.className)} style={props.style}>
            {props.noGradingDisplay}
          </div>
        );
      }
    }
    return <></>;
  } else {
    return (
      <GtnSelect
        name={props.name ?? ''}
        label={props.label ?? t('evaluation.gradings.default')}
        className={props.className}
        style={props.style}
        disabled={props.disabled}
        options={gradingValues}
        onChange={props.onChange}
        value={props.grading ? String(props.grading) : undefined}
      />
    );
  }
}
