import { CommonRoutingPaths } from '@gtn/app-common/routes/AppCommonRouting';
import { IconButton, Tooltip } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import unique from 'array-unique';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { useAppCommonSelector, useAppDispatch } from '@gtn/app-common/store/app.store.hooks';
import { navigationActions } from '@gtn/app-common/store/navigation.state';
import { ExacompAPI } from '@gtn/common/api/ExacompAPI';
import { MoodleCoreAPI } from '@gtn/common/api/MoodleCoreAPI';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import { GtnAvatar } from '@gtn/common/components/gtn-avatar/GtnAvatar';
import { LoadingIndicatorInline } from '@gtn/common/components/LoadingIndicator';
import { useAppTranslation, useHistory } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import styles from '@gtn/app-common/routes/profile/notifications/Notifications.module.scss';

export interface NotificationsProps {
  onAllNotificationsMarkedAsRead?: () => void;
}

export function Notifications(props: NotificationsProps) {
  const history = useHistory();
  const t = useAppTranslation();
  const dispatch = useAppDispatch();

  const moodleAPI = InjectionContainer.resolve(MoodleCoreAPI);

  const { data: notificationData, mutate: reloadNotifications } = useAPI(moodleAPI.getExacompNotifications, [], {
    revalidateOnFocus: true,
    refreshWhenHidden: false,
    refreshInterval: 30 * 1000,
  });

  const courses = useAppCommonSelector((state) => state.navigation.courses);

  const [isReloading, setIsReloading] = useState(false);

  const notifications = useMemo(() => {
    return notificationData?.notifications?.filter((n) => !n.read);
  }, [notificationData]);

  const userIds = unique(notifications?.map((notification) => notification.useridfrom) || []);

  const { data: users } = useAPI(moodleAPI.getUsersByField, userIds.length > 0 ? ['id', userIds] : null);

  const getUser = (id) => users?.find((user) => user.id === id);

  const markAsRead = async (notificationId?: number) => {
    setIsReloading(true);
    if (notificationId) {
      await moodleAPI.markNotificationRead(notificationId);
    } else {
      await moodleAPI.markAllNotificationsAsRead();
    }
    await reloadNotifications();
    setIsReloading(false);
  };

  return (
    <>
      <div className={styles.container}>
        <h2>{t('notifications.title')}</h2>
        {notifications?.length! > 0 && notificationData?.unreadcount !== 0 && (
          <Tooltip title={t('notifications.mark-all-as-read') as string}>
            <IconButton
              className={styles.dismissAll}
              onClick={async () => {
                await markAsRead();
                props?.onAllNotificationsMarkedAsRead?.();
              }}
            >
              <Cancel />
            </IconButton>
          </Tooltip>
        )}
      </div>

      {!notifications || !courses ? (
        <div>
          <LoadingIndicatorInline />
        </div>
      ) : notifications.length === 0 ? (
        <div>{t('notifications.empty')}</div>
      ) : (
        <div style={isReloading ? { opacity: '30%' } : {}}>
          {notifications.map((notification, index) => {
            const { customdata: customData } = notification;
            const userFrom = getUser(notification.useridfrom);

            const onClick =
              customData?.app === 'diggrplus' || !notification.read
                ? async () => {
                    if (customData?.app === 'diggrplus' && customData?.itemid > 0) {
                      // zum kurs springen, noch nicht als gelesen markieren
                      const course = courses.find((course) => course.id === customData?.courseid);
                      if (course) {
                        dispatch(navigationActions.setSelectedCourse(course));
                      }
                      dispatch(navigationActions.setSelectedStudent(undefined));

                      history.push(CommonRoutingPaths.WORKBOOK, {
                        typeFilter: null,
                        statusFilter: null,
                        seq: Math.random(), // small hack so workbook page triggers refresh
                      });
                      dispatch(navigationActions.setNotificationsDrawerOpened(false));
                    } else {
                      await markAsRead(notification.id);
                    }
                  }
                : undefined;

            return (
              <div
                key={index}
                className={classNames(styles.notification, {
                  [styles.clickable]: !notification.read,
                })}
                onClick={onClick}
              >
                {!notification.read && (
                  <Tooltip title={t('notifications.mark-as-read') as string}>
                    <IconButton
                      className={styles.dismiss}
                      onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        await markAsRead(notification.id);
                      }}
                    >
                      <Cancel />
                    </IconButton>
                  </Tooltip>
                )}

                <p className={styles.subject}>{notification.subject}</p>
                <div className={styles.secondLine}>
                  <GtnAvatar className={styles.userImage} imageUrl={userFrom?.profileimageurlsmall} name={userFrom?.fullname} />
                  <p className={styles.userName}>{userFrom?.fullname}</p>
                  <p>{new Date(notification.timecreated * 1000).toLocaleString()}</p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}
