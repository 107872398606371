import { Client } from '@microsoft/microsoft-graph-client';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { MSAuthManager } from '@gtn/app-common/microsoft/MSAuthManager';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';

export class MSGraphManager {
  msAuthManager = InjectionContainer.resolve(MSAuthManager);

  private graphClient?: Client;

  public init() {
    if (!this.graphClient) {
      this.graphClient = Client.init({
        authProvider: async (done) => {
          try {
            const accessToken = await this.msAuthManager.getAccessToken();
            done(null, accessToken);
          } catch (e) {
            done(e, null);
          }
        },
      });
    }
  }

  public async getMyJoinedTeams() {
    return this.get<{ value: MicrosoftGraph.Team[] }>('/me/joinedTeams');
  }

  private async get<T>(path: string): Promise<T> {
    return this.graphClient?.api(path).get();
  }
}
