import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppCommonState } from '@gtn/app-common/store/app.store';

export const useAppCommonSelector: TypedUseSelectorHook<AppCommonState> = useSelector;

export function useAppDispatch(): any {
  return useDispatch();
}

export function useSelectedCourse() {
  return useAppCommonSelector((state) => state.navigation.selectedCourse);
}

export function useSelectedStudent() {
  return useAppCommonSelector((state) => {
    if (Array.isArray(state.navigation.selectedStudent)) {
      return state.navigation.selectedStudent[0];
    }
    return state.navigation.selectedStudent;
  });
}
export function useSelectedStudents() {
  return useAppCommonSelector((state) => {
    const selectedStudent = state.navigation.selectedStudent;

    if (Array.isArray(selectedStudent)) {
      return selectedStudent;
    } else if (selectedStudent != null) {
      return [selectedStudent];
    }
    return selectedStudent;
  });
}
