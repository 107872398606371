import { AppType } from '@gtn/app-common/config/IAppConfig';
import { ConfigManagerToken } from '@gtn/common/config/ConfigManager';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { AppConfigManager } from '@gtn/app-common/config/AppConfigManager';

export function useAppConfig() {
  return InjectionContainer.resolve<AppConfigManager>(
    ConfigManagerToken
  ).getConfig();
}

export function useIsAppType(...appType: AppType[]) {
  return InjectionContainer.resolve<AppConfigManager>(
    ConfigManagerToken
  ).isAppType(...appType);
}
