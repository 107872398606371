import { AppCommonAPI } from '@gtn/app-common/api/AppCommonAPI';
import { useAppConfig } from '@gtn/app-common/config/AppConfigHooks';
import { CommonRoutingParams, CommonRoutingPaths } from '@gtn/app-common/routes/AppCommonRouting';
import { AppCommonState } from '@gtn/app-common/store/app.store';
import { MoodleCoreAPI } from '@gtn/common/api/MoodleCoreAPI';
import { HttpService } from '@gtn/common/api/webservice/HttpService';
import { preferencesActions } from '@gtn/common/store/preferences/preferences.state';
import { StoreToken } from '@gtn/common/store/shared.store';
import { userActions, UserRole } from '@gtn/common/store/user/user.state';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { Utils } from '@gtn/common/utils/Utils';
import { Store } from 'redux';
import { singleton } from 'tsyringe';

enum LoginAction {
  MS_TEAMS = 'msteams_login',
  MOODLE_TOKEN = 'login_result',
  GET_LOGIN_URL = 'get_login_url',
}
@singleton()
export class LoginService {
  private httpService = InjectionContainer.resolve(HttpService);
  private moodleCoreAPI = InjectionContainer.resolve(MoodleCoreAPI);
  private sharedAPI = InjectionContainer.resolve(AppCommonAPI);
  private readonly store = InjectionContainer.resolve<Store<AppCommonState>>(StoreToken);
  private config = useAppConfig();

  public async getLoginUrl(moodleUrl: string, returnUri?: string) {
    const payload = {
      ...this.createBaseLoginPayload(LoginAction.GET_LOGIN_URL),
      return_uri: returnUri ?? document.location.href.replace(/\?.*/, ''),
    };

    const result = await this.httpService.postWithJSONResponse<{
      login_url: string;
      error?: string;
    }>(this.getAppLoginUrl(moodleUrl), payload);

    if (!result.login_url) {
      throw new Error(result.error || 'Login url not defined!');
    }

    GtnLogger.log('login_url:', result.login_url);

    return result.login_url;
  }

  public async loginWithMoodleToken(moodleUrl: string, moodleToken: string) {
    const payload = {
      ...this.createBaseLoginPayload(LoginAction.MOODLE_TOKEN),
      moodle_token: moodleToken,
    };

    const result = await this.httpService.postWithJSONResponse<any>(this.getAppLoginUrl(moodleUrl), payload);

    const serviceTokens: any = {};
    for (const token of result.data.tokens) {
      serviceTokens[token.service] = token.token;
    }
    // first set webservice tokens, so webservices can access them
    this.store.dispatch(
      userActions.authTokenLoadSuccess({
        sessionKey: serviceTokens.sesskey,
        moodleMobile: serviceTokens.moodle_mobile_app,
        exacomp: serviceTokens.exacompservices,
        exaport: serviceTokens.exaportservices,
      })
    );
  }

  public async loginWithMSTeamsToken(moodleUrl: string, accessToken: string) {
    const basePath = Utils.normalizeBasePath(this.config.basePath ?? '');
    const otherParams = window.location.search?.length ? window.location.search.substr(1) : '';
    const loginUrl = `${document.location.origin + basePath}/${CommonRoutingPaths.LOGIN}?${CommonRoutingParams.MS_TEAMS_NOTIFY_AUTH_SUCCESS}=true&${otherParams}`;

    const payload = {
      ...this.createBaseLoginPayload(LoginAction.MS_TEAMS),
      access_token: accessToken,
      return_uri: loginUrl,
    };

    const result = await this.httpService.postWithJSONResponse<{
      moodle_token?: string;
      login_url?: string;
      error?: string;
    }>(this.getAppLoginUrl(moodleUrl), payload);

    return result;
  }

  public async loadUserData() {
    const [siteInfo, userInfo] = await Promise.all([this.moodleCoreAPI.getSiteInfo(), this.sharedAPI.getUserInfo()]);

    // then profile load success, and isLoggedIn=true
    this.store.dispatch(
      userActions.userProfileLoadSuccess({
        userName: siteInfo.username,
        pictureUrl: siteInfo.userpictureurl,
        fullName: siteInfo.fullname,
        id: siteInfo.userid,
        firstName: siteInfo.firstname,
        lastName: siteInfo.lastname,
        role: userInfo.role === UserRole.Teacher ? UserRole.Teacher : UserRole.Student,
      })
    );
  }

  private getAppLoginUrl(moodleUrl: string) {
    return moodleUrl?.replace(/\/$/, '') + '/blocks/exacomp/applogin_diggr_plus.php';
  }

  private createBaseLoginPayload(action: LoginAction) {
    return {
      action,
      services: 'moodle_mobile_app,exacompservices,exaportservices',
      app: this.config.appType === 'set' ? 'set-app' : this.config.appType,
      app_version: this.config.version.name,
      app_token: 'diggr-0.1218135469049173',
    };
  }

  saveInitialQueryParams() {
    const params = new URLSearchParams(window.location.search?.slice(1));
    params.delete(CommonRoutingParams.TRIGGER_AUTO_LOGIN);
    params.delete(CommonRoutingParams.MOODLE_TOKEN);

    this.store.dispatch(
      preferencesActions.set({
        initialQueryParams: params.toString(),
      })
    );
  }

  getInitialQueryParams() {
    return this.store.getState().preferences.initialQueryParams;
  }
}
