import { CommonRoutingPaths } from '@gtn/app-common/routes/AppCommonRouting';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FolderShared, KeyboardArrowRight, People } from '@material-ui/icons';
import { Login } from '@microsoft/mgt-react';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import classNames from 'classnames';
import React, { useState } from 'react';
import { DiggrPlusAPI } from '@gtn/diggr/api/DiggrPlusAPI';
import { MSAuthManager } from '@gtn/app-common/microsoft/MSAuthManager';
import { MSGraphManager } from '@gtn/app-common/microsoft/MSGraphManager';
import { MSTeamsManager } from '@gtn/app-common/microsoft/MSTeamsManager';
import { useAppCommonSelector, useAppDispatch } from '@gtn/app-common/store/app.store.hooks';
import { navigationActions } from '@gtn/app-common/store/navigation.state';
import { ExacompAPI } from '@gtn/common/api/ExacompAPI';
import { Course } from '@gtn/common/api/model/Course';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import { AlertDialog } from '@gtn/common/components/alert-dialog/AlertDialog';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import GtnDialog, { DialogProps, useGtnDialog } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { useAppTranslation, useForceUpdate, useHistory } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { ProgressState } from '@gtn/common/utils/ProgressState';
import useAsyncEffect from 'use-async-effect';
import styles from '@gtn/diggr/components/ms-teams-import-dialog/MSTeamsImportDialog.module.scss';

export interface MsTeamsImportDialogProps {}

export default function MSTeamsImportDialog(props: MsTeamsImportDialogProps & DialogProps) {
  const t = useAppTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const forceUpdate = useForceUpdate();

  const diggrPlusAPI = InjectionContainer.resolve(DiggrPlusAPI);
  const msAuthManager = InjectionContainer.resolve(MSAuthManager);
  const msGraphManager = InjectionContainer.resolve(MSGraphManager);
  const msTeamsManager = InjectionContainer.resolve(MSTeamsManager);

  const courses = useAppCommonSelector((state) => state.navigation.courses);
  const importFinishedDialog = useGtnDialog(AlertDialog);

  const [teams, setTeams] = useState<MicrosoftGraph.Team[]>();
  const [selectedTeam, setSelectedTeam] = useState<MicrosoftGraph.Team>();
  const [selectedCourse, setSelectedCourse] = useState<Course>();
  const [importProgressState, setImportProgressState] = useState<ProgressState>(ProgressState.Empty);
  const [msTeamsLoginFailed, setMsTeamsLoginFailed] = useState<boolean>(false);

  useAsyncEffect(async () => {
    setMsTeamsLoginFailed(false);
    try {
      const teamsContext = await msTeamsManager.getContext();
      GtnLogger.log('MS Teams context: %o', teamsContext);

      try {
        await msAuthManager.trySilentMSTeamsLogin(teamsContext.tid!!);
      } catch (e) {
        GtnLogger.warn('Failed to get auth token from MS Teams: ' + e);
        setMsTeamsLoginFailed(true);
      }

      forceUpdate();
    } catch (e) {
      GtnLogger.warn(e);
    }
  }, []);

  useAsyncEffect(async () => {
    if (msAuthManager.isLoggedIn()) {
      let joinedTeams: MicrosoftGraph.Team[] | undefined;

      try {
        msGraphManager.init();
        joinedTeams = (await msGraphManager.getMyJoinedTeams()).value;
      } catch (e) {
        GtnLogger.warn(e);
      }

      try {
        const teamsContext = await msTeamsManager.getContext();
        const groupId = teamsContext?.groupId;

        if (groupId) {
          if (!joinedTeams?.length) {
            joinedTeams = [{ id: groupId, displayName: teamsContext.teamName }];
          }

          const currentTeam = joinedTeams.find((team) => team.id === groupId);
          setSelectedTeam(currentTeam);
        }
      } catch (e) {
        GtnLogger.warn(e);
      }

      setTeams(joinedTeams);
    } else {
      setTeams(undefined);
      setSelectedTeam(undefined);
    }
  }, [msAuthManager.isLoggedIn()]);

  async function startImport() {
    try {
      setImportProgressState(ProgressState.Loading);
      const result = await diggrPlusAPI.importMSTeamsStudents(selectedCourse!.id, await msAuthManager.getAccessToken(), selectedTeam!.id!);

      setImportProgressState(ProgressState.Content);
      importFinishedDialog.open({
        title: t('ms-teams-import.finished-title'),
        message: t('ms-teams-import.finished-message', {
          totalUsers: result.total_count,
          importedUsers: result.imported_count,
        }),
        hideSecondaryButton: true,
        onClose: () => {
          dispatch(navigationActions.setSelectedCourse(selectedCourse));
          history.push(CommonRoutingPaths.HOME);
          props.onClose?.();
        },
      });
    } catch (e) {
      GtnLogger.warn(e);
      setImportProgressState(ProgressState.Error);
    }
  }

  return (
    <GtnDialog {...props} title={t('ms-teams-import.title')}>
      <div className={classNames(classNames(styles.dialogContentContainer, styles.scrollContainer))}>
        <p className={styles.helpText}>{t('ms-teams-import.help')}</p>

        {msTeamsLoginFailed && <p className={styles.msTeamsLoginFailed}>{t('ms-teams-import.ms-teams-login-failed')}</p>}

        {!msAuthManager.isLoggedInViaMSTeams() && (
          <Login
            loginCompleted={forceUpdate}
            loginFailed={forceUpdate}
            logoutCompleted={() => {
              msAuthManager.clearTokens();
              forceUpdate();
            }}
          />
        )}

        {msAuthManager.isLoggedIn() && (
          <>
            <div className={styles.chooserContainer}>
              <div className={styles.list}>
                <h3>{t('ms-teams-import.choose-team')}</h3>
                {teams?.length === 0 && <p>{t('ms-teams-import.no-teams')}</p>}
                {teams?.map((team) => (
                  <ListItem key={`team-${team.id}`} className={classNames(styles.listItem, selectedTeam?.id === team.id ? styles.selected : null)} onClick={() => setSelectedTeam(team)} button>
                    <ListItemIcon className={styles.allUsersIcon + ' ' + styles.icon}>
                      <People />
                    </ListItemIcon>
                    <ListItemText primary={team.displayName} />
                  </ListItem>
                ))}
              </div>

              {selectedTeam && (
                <>
                  <KeyboardArrowRight className={styles.arrowIcon} />

                  <div className={styles.list}>
                    <h3>{t('ms-teams-import.choose-course')}</h3>
                    {courses?.map((course) => (
                      <ListItem
                        key={`course-${course.id}`}
                        className={classNames(styles.listItem, selectedCourse?.id === course.id ? styles.selected : null)}
                        onClick={() => setSelectedCourse(course)}
                        button
                      >
                        <ListItemIcon className={styles.allUsersIcon + ' ' + styles.icon}>
                          <FolderShared />
                        </ListItemIcon>
                        <ListItemText primary={course.displayname} />
                      </ListItem>
                    ))}
                  </div>
                </>
              )}
            </div>

            <div className={styles.submitContainer}>
              <p>{importProgressState === ProgressState.Error ? t('ms-teams-import.import-failed') : ''}</p>

              <GtnButton
                label={t('ms-teams-import.start-import')}
                actionType="primary"
                disabled={!(selectedTeam && selectedCourse)}
                onClick={startImport}
                loading={importProgressState === ProgressState.Loading}
              />
            </div>
          </>
        )}
      </div>

      <importFinishedDialog.Component />
    </GtnDialog>
  );
}
