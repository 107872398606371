import { CommonRoutingPaths } from '@gtn/app-common/routes/AppCommonRouting';
import Settings from '@gtn/app-common/routes/settings/Settings';
import styles from '@gtn/app-common/routes/settings/Settings.module.scss';
import { ServerInfoManager } from '@gtn/app-common/utils/ServerInfoManager';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import { useGtnDialog } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { useIsTeacher } from '@gtn/common/store/user/user.hooks';
import { useAppTranslation, useHistory } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import MSTeamsImportDialog from '@gtn/diggr/components/ms-teams-import-dialog/MSTeamsImportDialog';
import React from 'react';

export function DiggrSettings() {
  const t = useAppTranslation();
  const history = useHistory();
  const serverInfo = InjectionContainer.resolve(ServerInfoManager).getServerInfo();

  const isTeacher = useIsTeacher();
  const showTeamsImport = isTeacher && serverInfo?.msteams_azure_app_client_id && serverInfo?.msteams_import_enabled;
  const msTeamsImportDialog = useGtnDialog(MSTeamsImportDialog);

  return (
    <Settings>
      {isTeacher && (
        <>
          <hr />
          <h2>{t('settings.competence-areas')}</h2>
          <p className={styles.competenceFieldsHelp}>{t('settings.choose-competencies-help')}</p>
          <GtnButton actionType="primary" label={t('settings.choose-competencies')} onClick={() => history.push(CommonRoutingPaths.COURSE_TOPICS)} />
        </>
      )}

      {showTeamsImport && (
        <>
          <br />
          <hr />
          <br />
          <GtnButton actionType="primary" label={t('ms-teams-import.title')} onClick={() => msTeamsImportDialog.open()} />
        </>
      )}

      <msTeamsImportDialog.Component />
    </Settings>
  );
}
