import { StyleProps } from '@gtn/common/components/StyleProps';
import { Avatar, Chip } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import styles from '@gtn/common/components/filter-bar/FilterBar.module.scss';

export interface Filter<T> {
  displayName: string;
  value: T;
  icon?: string | React.ReactElement; // CSS color or icon
}

export interface FilterBarProps<T> extends StyleProps {
  filters: Filter<T>[];
  variant?: 'filled' | 'outlined';
  selectedFilter?: T;
  onFilterSelected?: (filter: Filter<T>) => void;
}

export default function FilterBar<T>(props: FilterBarProps<T>) {
  function renderAvatar(filter: Filter<T>) {
    if (filter.icon) {
      if (typeof filter.icon === 'string') {
        return (
          <Avatar className={styles.avatar} style={{ backgroundColor: filter.icon }}>
            &nbsp;
          </Avatar>
        );
      } else {
        return <div className={styles.avatar}>{filter.icon}</div>;
      }
    }

    return undefined;
  }

  return (
    <div className={classNames(styles.container, props.className)} style={props.style}>
      {props.filters.map((filter) => (
        <Chip
          key={String(filter.value)}
          className={styles.chip}
          avatar={renderAvatar(filter)}
          variant={props.variant === 'outlined' ? 'outlined' : 'default'}
          label={filter.displayName}
          onClick={() => props.onFilterSelected?.(filter)}
          color={props.selectedFilter === filter.value ? 'secondary' : 'default'}
        />
      ))}
    </div>
  );
}
