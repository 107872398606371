import { DEFAULT_APP_CONFIG } from '@gtn/app-common/config/default-app.config';
import { AppType } from '@gtn/app-common/config/IAppConfig';
import { IDiggrConfig } from '@gtn/diggr/config/IDiggrConfig';

export const DEFAULT_DIGGR_CONFIG: IDiggrConfig = {
  ...DEFAULT_APP_CONFIG,
  appType: AppType.DIGGR_PLUS,
  showLoginTutorial: false,
  theme: {
    appName: 'diggr+',
    colors: {
      primary: '#3B963E',
      text1OnPrimary: '#fff',

      secondary: '#FFB22D',
      text1OnSecondary: '#fff',

      error: '#f44336',
      text1OnError: '#fff',

      input: '#E3E5E7',
      text1OnInput: '#79797A',

      tertiaryButton: '#ABB6BE',
      text1OnTertiaryButton: 'white',

      foreground: '#fff',
      text1OnForeground: '#292929',
      text2OnForeground: '#767676',

      background: '#F4F4F4',
      text1OnBackground: 'rgba(0, 0, 0, 0.87)',
      text2OnBackground: 'rgba(0, 0, 0, 0.54)',
    },
  },
};
