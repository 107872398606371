import { AddCircleRounded } from '@material-ui/icons';
import {
  DropzoneArea as MaterialUiDropzone,
  DropzoneAreaProps,
} from 'material-ui-dropzone';
import React from 'react';
import { StyleProps } from '@gtn/common/components/StyleProps';
import styles from '@gtn/common/components/forms/gtn-file-upload/GtnFileUpload.module.scss';

const GtnFileUpload = (
  props: DropzoneAreaProps & { disabled?: boolean } & StyleProps
) => (
  <div className={props.className} style={props.style}>
    <MaterialUiDropzone
      showPreviews={false}
      showPreviewsInDropzone={false}
      inputProps={{ disabled: props.disabled }}
      Icon={AddCircleRounded as any}
      dropzoneClass={styles.container}
      dropzoneText=""
      alertSnackbarProps={{ autoHideDuration: 2000 }}
      {...props}
    />
  </div>
);

export default GtnFileUpload;
