import { AppCommonAPI } from '@gtn/app-common/api/AppCommonAPI';
import { ExamplesTreeDescriptor, ExamplesTreeExample, ExamplesTreeResponse, ExamplesTreeSubject, ExamplesTreeTopic, GradeableElement } from '@gtn/app-common/api/model/ExamplesTreeResponse';

export interface NavigatableElement extends GradeableElement {
  id: number;
  visible?: boolean;
  used?: boolean;
  parent?: NavigatableElement;
  children?: NavigatableElement[];
  type: 'example' | 'childdescriptor' | 'descriptor' | 'topic';
}

export interface NavigatableExamplesTreeExample extends ExamplesTreeExample, NavigatableElement {}

export interface NavigatableExamplesTreeDescriptor extends ExamplesTreeDescriptor, NavigatableElement {
  examples: NavigatableExamplesTreeExample[];
  childdescriptors?: NavigatableExamplesTreeDescriptor[];
}

export interface NavigatableExamplesTreeTopic extends ExamplesTreeTopic, NavigatableElement {
  descriptors: NavigatableExamplesTreeDescriptor[];
}

export interface NavigatableExamplesTreeSubject extends ExamplesTreeSubject, NavigatableElement {
  topics: NavigatableExamplesTreeTopic[];
}

export class ExampleVisibilityManager {
  private readonly appCommonAPI: AppCommonAPI;
  private readonly courseId: number;

  constructor(appCommonAPI: AppCommonAPI, courseId: number) {
    this.appCommonAPI = appCommonAPI;
    this.courseId = courseId;
  }

  async toggleNodeVisibility(node: NavigatableElement) {
    switch (node.type) {
      case 'example':
        await this.changeExampleVisibility(node);
        break;
      case 'descriptor':
        await this.changeDescriptorVisibility(node);
        break;
      case 'childdescriptor':
        await this.changeChildDescriptorVisibility(node);
        break;
      default:
        await this.changeTopicVisibility(node);
        break;
    }
  }

  createNavigateableExamplesTree(examplesTree?: ExamplesTreeResponse) {
    if (examplesTree) {
      for (const subject of examplesTree) {
        for (const topic of subject.topics) {
          topic['children'] = topic.descriptors;
          topic['type'] = 'topic';

          for (const descriptor of topic.descriptors) {
            descriptor['parent'] = topic;
            descriptor['children'] = [...(descriptor.examples ?? []), ...(descriptor.childdescriptors ?? [])];
            descriptor['type'] = 'descriptor';

            if (descriptor.childdescriptors) {
              for (const childDescriptor of descriptor.childdescriptors) {
                childDescriptor['parent'] = descriptor;
                childDescriptor['children'] = childDescriptor.examples;
                childDescriptor['type'] = 'descriptor';

                for (const example of childDescriptor.examples) {
                  example['parent'] = childDescriptor;
                  example['type'] = 'example';
                }
              }
            }

            for (const example of descriptor.examples) {
              example['parent'] = descriptor;
              example['type'] = 'example';
            }
          }
        }
        subject['children'] = subject.topics;
      }
    }
    return examplesTree as NavigatableExamplesTreeSubject[];
  }

  shouldNodeByShownAsVisible(node: NavigatableElement) {
    return node.visible && (node.parent ? this.shouldNodeByShownAsVisible(node.parent) : true);
  }

  areAllChildrenVisible(node: NavigatableElement) {
    return node.children?.every((e) => e.visible && this.areAllChildrenVisible(e)) ?? true;
  }

  private async changeExampleVisibility(node: NavigatableElement, visibility?: boolean) {
    let shouldBeVisible = visibility ?? !node.visible;

    if (node.parent?.visible === false) {
      shouldBeVisible = true;
      await this.makeParentVisible(node);
    }

    if (node.parent?.parent?.visible === false) {
      shouldBeVisible = true;
      await this.makeParentVisible(node.parent);
    }

    await this.setNodeVisibility(node, shouldBeVisible);

    if (!shouldBeVisible && node.parent?.children?.every((c) => !c.visible)) {
      await this.setNodeVisibility(node.parent, false);
    }

    const root = node.parent?.parent?.parent ?? node.parent?.parent ?? node.parent;
    this.setDuplicatedExamplesVisibility(root, node.id, shouldBeVisible);
  }
  private setDuplicatedExamplesVisibility(root: NavigatableElement | undefined, id: number, visibility: boolean | undefined) {
    root?.children?.forEach((child) => {
      if (child.type === 'example' && child.id === id) {
        child.visible = visibility;
        return;
      }
      this.setDuplicatedExamplesVisibility(child, id, visibility);
    });
  }

  private async changeDescriptorVisibility(node: NavigatableElement, visibility?: boolean) {
    let shouldBeVisible = visibility ?? !node.visible;

    if (node.parent?.visible === false) {
      shouldBeVisible = true;
      await this.makeParentVisible(node);
    }

    const allChildrenVisible = this.areAllChildrenVisible(node);
    if (shouldBeVisible || allChildrenVisible) {
      await this.setNodeVisibility(node, shouldBeVisible);
    }

    const shouldChildrenBeVisible = shouldBeVisible || !allChildrenVisible;
    await this.changeChildrenVisibility(node, shouldChildrenBeVisible);

    if (!shouldBeVisible && node.parent?.children?.every((c) => !c.visible)) {
      await this.setNodeVisibility(node.parent, false);
    }
  }

  private async changeChildDescriptorVisibility(node: NavigatableElement, visibility?: boolean) {
    let shouldBeVisible = visibility ?? !node.visible;

    if (node.parent?.visible === false) {
      shouldBeVisible = true;
      await this.makeParentVisible(node);
    }

    const allChildrenVisible = this.areAllChildrenVisible(node);
    if (shouldBeVisible || allChildrenVisible) {
      await this.setNodeVisibility(node, shouldBeVisible);
    }

    const shouldChildrenBeVisible = shouldBeVisible || !allChildrenVisible;
    await this.changeChildrenVisibility(node, shouldChildrenBeVisible);

    if (!shouldBeVisible && node.parent?.children?.every((c) => !c.visible)) {
      await this.setNodeVisibility(node.parent, false);
    }
  }

  private async changeTopicVisibility(node: NavigatableElement, visibility?: boolean) {
    const shouldBeVisible = visibility ?? !node.visible;
    const allChildrenVisible = this.areAllChildrenVisible(node);

    if (shouldBeVisible || allChildrenVisible) {
      await this.setNodeVisibility(node, !node.visible);
    }

    const shouldChildrenBeVisible = shouldBeVisible || !allChildrenVisible;
    await this.changeChildrenVisibility(node, shouldChildrenBeVisible);
  }

  private async makeParentVisible(node: NavigatableElement) {
    if (node.parent) {
      await this.setNodeVisibility(node.parent, true);
      await this.changeChildrenVisibility(node.parent, false, node.id);
    }
  }

  private async changeChildrenVisibility(node: NavigatableElement, visible: boolean, excludeId?: number) {
    const children = node.children?.filter((c) => c.id !== excludeId);
    if (children) {
      await this.setNodesVisibility(children, visible);

      await Promise.all(children.map((c) => this.changeChildrenVisibility(c, visible)));
    }
  }

  private async setNodeVisibility(node: NavigatableElement, visible: boolean) {
    return this.setNodesVisibility([node], visible);
  }

  private async setNodesVisibility(nodes: NavigatableElement[], visible: boolean) {
    if (nodes.length > 0) {
      await this.appCommonAPI.updateVisibility(
        this.courseId,
        nodes.map((e) => e.id),
        visible,
        nodes[0].type
      );
      nodes.forEach((e) => (e.visible = visible));
    }
  }
}
