import { appCommonReducer } from '@gtn/app-common/store/app.store';
import { navigationFeatureKey } from '@gtn/app-common/store/navigation.state';
import { preferencesFeatureKey } from '@gtn/common/store/preferences/preferences.state';
import { userFeatureKey } from '@gtn/common/store/user/user.state';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';

const appReducer = combineReducers({
  ...appCommonReducer,
});

export type DiggrPlusState = ReturnType<typeof appReducer>;

const persistConfig = {
  key: 'root-diggr-plus',
  storage,
  whitelist: [userFeatureKey, preferencesFeatureKey, navigationFeatureKey],
  stateReconciler: autoMergeLevel2,
};

export const diggrPlusStore = configureStore({
  reducer: persistReducer(persistConfig, appReducer as any),
});
