import { Form, Formik, FormikProps } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import * as React from 'react';
import { StyleProps } from '@gtn/common/components/StyleProps';

export interface GtnFormProps<T> extends StyleProps {
  initialValues?: Partial<T>;
  validationSchema?: any;
  onSubmit?: (
    values: Partial<T>,
    formikHelpers: FormikHelpers<Partial<T>>
  ) => void | Promise<any>;
  children?:
    | React.ReactNode
    | ((props: FormikProps<Partial<T>>) => React.ReactNode);
  // @ts-ignore
  onKeyUp?: (
    event: KeyboardEvent<HTMLFormElement>,
    props: FormikProps<Partial<T>>
  ) => void;
}

export default function GtnForm<T>(props: GtnFormProps<T>) {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={props.initialValues ?? {}}
      validationSchema={props.validationSchema}
      onSubmit={(values: Partial<T>, helpers: FormikHelpers<Partial<T>>) => {
        helpers.setSubmitting(true);
        props.onSubmit?.(values, helpers);
      }}
    >
      {(...params) => (
        <Form
          className={props.className}
          style={props.style}
          onKeyUp={(event) => props.onKeyUp?.(event, ...params)}
        >
          {typeof props.children === 'function'
            ? props.children(...params)
            : props.children}
        </Form>
      )}
    </Formik>
  );
}
