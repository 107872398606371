import { AppCommonAPI } from '@gtn/app-common/api/AppCommonAPI';
import { StudentEnrolCode } from '@gtn/app-common/api/model/StudentEnrolCode';
import { AppConfigManager } from '@gtn/app-common/config/AppConfigManager';
import { CommonRoutingParams, CommonRoutingPaths } from '@gtn/app-common/routes/AppCommonRouting';
import { useAppCommonSelector } from '@gtn/app-common/store/app.store.hooks';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import LoadingContainer from '@gtn/common/components/loading-container/LoadingContainer';
import GtnDialog, { GtnDialogProps } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { ConfigManagerToken } from '@gtn/common/config/ConfigManager';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { ProgressState } from '@gtn/common/utils/ProgressState';
import { Utils } from '@gtn/common/utils/Utils';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import QRCode from 'react-qr-code';

import useAsyncEffect from 'use-async-effect';
import styles from './InviteStudentsDialog.module.scss';

interface InviteStudentsDialogProps extends GtnDialogProps {
  courseId?: number;
}

export const InviteStudentsDialog = (props: InviteStudentsDialogProps) => {
  const t = useAppTranslation();
  const configManager = InjectionContainer.resolve<AppConfigManager>(ConfigManagerToken);
  const config = configManager.getConfig();
  const preferences = useAppCommonSelector((state) => state.preferences);

  const appCommonAPI = InjectionContainer.resolve(AppCommonAPI);
  const { data: getEnrolcode, progressState: getEnrolCodeProgressState } = useAPI(appCommonAPI.getStudentEnrolCode, [props.courseId!]);
  const [enrolcode, setEnrolcode] = useState<StudentEnrolCode | undefined>();

  useAsyncEffect(async () => {
    if (getEnrolcode != null) {
      setEnrolcode(getEnrolcode);
    }
  }, [getEnrolcode]);

  const link = useMemo(() => {
    if (enrolcode?.code) {
      const params = new URLSearchParams();
      params.set(CommonRoutingParams.ENROL_CODE, enrolcode.code);
      // use url of currently signed in moodle
      params.set(CommonRoutingParams.MOODLE_URL, preferences.moodleUrl!);
      params.set(CommonRoutingParams.ALLOW_CHANGE_MOODLE_URL, String(false));

      const basePath = Utils.normalizeBasePath(config.basePath);
      return `${document.location.origin + basePath}/${CommonRoutingPaths.LOGIN}?${params.toString()}`;
    }
    return undefined;
  }, [enrolcode]);

  async function createNewEnrollmentCode() {
    try {
      setEnrolcode(undefined);
      const createEnrolcodeResponse = await appCommonAPI.createStudentEnrolCode(props.courseId!);
      setEnrolcode(createEnrolcodeResponse);
    } catch (e) {
      GtnLogger.warn(e);
    }
  }

  function getValidUntilText() {
    const validUntilDate = new Date((enrolcode?.valid_until ?? 0) * 1000);
    return t('invite-students.valid-until', {
      date: validUntilDate.toLocaleDateString() + ' ' + validUntilDate.toLocaleTimeString(),
    });
  }

  return (
    <GtnDialog {...props} title={t('invite-students.title')}>
      <div className={classNames(styles.dialogContentContainer, styles.container)}>
        <LoadingContainer
          className={styles.codeContainer}
          emptyText={''}
          state={enrolcode == null ? getEnrolCodeProgressState : ProgressState.Content}
          style={{
            alignItems: getEnrolCodeProgressState === ProgressState.Loading ? 'center' : 'stretch',
            justifyContent: getEnrolCodeProgressState === ProgressState.Loading ? 'center' : 'flex-start',
          }}
        >
          <div className={styles.textContainer}>
            <h2>{enrolcode?.code}</h2>
            {enrolcode?.valid_until && <p className={styles.validUntil}>{getValidUntilText()}</p>}

            {link && window.isSecureContext && (
              <GtnButton label={t('invite-students.copy-link')} actionType="secondary" className={styles.copyLinkButton} onClick={() => navigator.clipboard.writeText(link)}></GtnButton>
            )}
          </div>

          {link && <QRCode value={link} bgColor="transparent" className={styles.qrCode} />}
        </LoadingContainer>

        <GtnButton label={t('invite-students.create-new-enrolcode')} actionType="primary" className={styles.createNewCodeButton} onClick={() => createNewEnrollmentCode()}></GtnButton>
      </div>
    </GtnDialog>
  );
};
