import { AppConfigManager } from '@gtn/app-common/config/AppConfigManager';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import { ConfigManagerToken } from '@gtn/common/config/ConfigManager';
import { TranslationManager } from '@gtn/common/i18n/TranslationManager';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { Utils } from '@gtn/common/utils/Utils';
import React, { useEffect, useRef, useState } from 'react';

interface PdfViewerProps {
  fileUrl: string;
  fileName?: string;
  onSave?: (pdfContent: ArrayBuffer, originalFileName?: string) => void;
}

export default function PdfViewer(props: PdfViewerProps) {
  const t = useAppTranslation();
  const containerRef = useRef(null);
  const configManager = InjectionContainer.resolve<AppConfigManager>(ConfigManagerToken);
  const translationManager = InjectionContainer.resolve(TranslationManager);
  const [pspdfInstance, setPspdfInstance] = useState<any>();

  useEffect(() => {
    const container = containerRef.current;
    let instance, PSPDFKit;
    (async function () {
      PSPDFKit = await import('pspdfkit');
      instance = await PSPDFKit.load({
        licenseKey: configManager.getConfig().pspdfLicenceKey,
        // Container where PSPDFKit should be mounted.
        container,
        locale: translationManager.getLanguage(),
        document: props.fileUrl,
        // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
        baseUrl: `${window.location.protocol}//${window.location.host}${Utils.normalizeBasePath(configManager.getConfig().basePath)}/`,
      });
      setPspdfInstance(instance);
    })();

    return () => PSPDFKit && PSPDFKit.unload(container);
  }, []);

  async function save() {
    if (pspdfInstance) {
      const result = await pspdfInstance.exportPDF();
      if (result) {
        props.onSave?.(result, props.fileName);
      }
    }
  }

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', padding: '4px 16px', flexDirection: 'row-reverse' }}>
        <GtnButton actionType="primary" label={t('submit-item.save')} onClick={save} disabled={pspdfInstance == null} />
      </div>
      <div ref={containerRef} style={{ width: '100%', flex: '1' }} />
    </div>
  );
}
