import { getCommonRoutes } from '@gtn/app-common/CommonRoutes';
import { AppNavigation } from '@gtn/app-common/components/app-navigation/AppNavigation';
import { CommonRoutingPaths } from '@gtn/app-common/routes/AppCommonRouting';
import { useIsTeacher, useRole } from '@gtn/common/store/user/user.hooks';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import { GtnRoute } from '@gtn/common/utils/routing/GtnRoute';
import CompetenceProfile from '@gtn/app-common/components/competence-profile/CompetenceProfile';
import Examples from '@gtn/app-common/components/examples/Examples';
import Workbook from '@gtn/app-common/components/workbook/Workbook';
import { DiggrSettings } from '@gtn/diggr/components/settings/DiggrSettings';
import CourseTopics from '@gtn/app-common/routes/course-topics/CourseTopics';
import { Assessment, FolderShared } from '@material-ui/icons';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Redirect } from 'react-router-dom';

export function DiggrPlusApp() {
  const t = useAppTranslation();
  const isTeacher = useIsTeacher();
  const userRole = useRole();

  const routes: GtnRoute[] = [
    {
      path: CommonRoutingPaths.HOME,
      exact: true,
      content: () => <Redirect to={`/${CommonRoutingPaths.WORKBOOK}`} />,
    },
    {
      path: `/${CommonRoutingPaths.WORKBOOK}`,
      exact: true,
      content: () => <Workbook showTypeFilter={true} />,
      title: `menu.workbook.${userRole}`,
    },
    {
      path: `/${CommonRoutingPaths.COMPETENCE_PROFILE}`,
      content: () => <CompetenceProfile />,
      title: `menu.competencies.${userRole}`,
    },
    {
      path: `/${CommonRoutingPaths.EXAMPLES}`,
      content: () => <Examples />,
      title: `menu.examples.teacher`,
      blocked: !isTeacher,
    },
    {
      path: `/${CommonRoutingPaths.COURSE_TOPICS}`,
      content: () => <CourseTopics />,
      title: `settings.choose-competencies`,
      backRoute: `/${CommonRoutingPaths.SETTINGS}`,
      blocked: !isTeacher,
    },
    {
      path: `/${CommonRoutingPaths.SETTINGS}`,
      content: () => <DiggrSettings />,
      title: 'menu.settings',
    },
    ...getCommonRoutes(),
  ];

  const navItems = isTeacher
    ? [
        {
          title: t.withRole('menu.workbook'),
          href: CommonRoutingPaths.WORKBOOK,
          icon: <FolderShared />,
        },
        {
          title: t.withRole('menu.competencies'),
          href: CommonRoutingPaths.COMPETENCE_PROFILE,
          icon: <Assessment />,
        },
        {
          title: t.withRole('menu.examples'),
          href: CommonRoutingPaths.EXAMPLES,
          icon: <AssignmentIcon />,
        },
      ]
    : [
        {
          title: t.withRole('menu.workbook'),
          href: CommonRoutingPaths.WORKBOOK,
          icon: <FolderShared />,
        },
        {
          title: t.withRole('menu.competencies'),
          href: CommonRoutingPaths.COMPETENCE_PROFILE,
          icon: <Assessment />,
        },
      ];

  return <AppNavigation routes={routes} primaryNavigationItems={navItems} />;
}
