import React from 'react';
import ReactDOM from 'react-dom';
import 'reflect-metadata';
import AppShell from '@gtn/app-common/AppShell';
import { AppConfigManager } from '@gtn/app-common/config/AppConfigManager';
import { DEFAULT_DIGGR_CONFIG } from '@gtn/diggr/config/default-diggr.config';
import '@gtn/app-common/styles/index.scss';
import { ConfigManagerToken } from '@gtn/common/config/ConfigManager';
import { StoreToken } from '@gtn/common/store/shared.store';
import { GtnLogger, LogLevel } from '@gtn/common/utils/logger/GtnLogger';
import { IDiggrConfig } from '@gtn/diggr/config/IDiggrConfig';
import { DiggrPlusApp } from '@gtn/diggr/DiggrPlusApp';
import { diggrPlusStore } from '@gtn/diggr/DiggrPlusStore';
import { container } from 'tsyringe';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

GtnLogger.setLogLevel(LogLevel.Info);

container.registerInstance(StoreToken, diggrPlusStore);
container.registerInstance(
  ConfigManagerToken,
  new AppConfigManager<IDiggrConfig>(DEFAULT_DIGGR_CONFIG)
);

ReactDOM.render(
  <React.StrictMode>
    <AppShell>
      <DiggrPlusApp />
    </AppShell>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();
